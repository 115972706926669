import React from "react";
import { PlainI18nProps } from "shared/types/i18n";

import { Text } from "../text";

export type SliderProps = {
  className?: string;
  label?: PlainI18nProps;
  allowedValues?: number[];
  value: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  onSubmitValue: (submittedValue: number) => void;
};

export const Slider: React.FC<SliderProps> = ({
  label,
  className,
  onChange,
  onSubmitValue,
  allowedValues,
  value,
  min = allowedValues ? Math.min(...allowedValues) : 0,
  max = allowedValues ? Math.max(...allowedValues) : 1,
}) => {
  return (
    <div className={className}>
      <div className="mb-2 ml-2">
        <Text {...label} />
        {": "}
        {value}
      </div>
      <input
        min={min}
        max={max}
        type="range"
        value={value}
        className="range range-primary"
        onPointerUp={() => onSubmitValue(value)}
        onChange={(e) => onChange(Number(e.target.value))}
      />
    </div>
  );
};
