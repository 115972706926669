import { Hub } from "aws-amplify/utils";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { cognitoPaths } from "./paths";
import { AuthMessageLevel, AuthNavigateState } from "../../generic/types";
import { useCognitoConfiguration } from "../hooks/use-cognito-configuration";

export const CognitoSocialRedirectHandler: React.FC = () => {
  const signInSuccessful = useRef(false);
  const navigate = useNavigate();
  useCognitoConfiguration();
  useEffect(
    () =>
      Hub.listen("auth", ({ payload }) => {
        switch (payload.event) {
          case "signedIn":
            signInSuccessful.current = true;
            break;
          case "customOAuthState":
            {
              const { originator, state } = JSON.parse(payload.data);
              if (signInSuccessful.current) {
                navigate(state.redirectUrl ?? "/");
              } else {
                navigate(originator, {
                  replace: true,
                  state: {
                    ...state,
                    message: {
                      level: AuthMessageLevel.ERROR,
                      tx: "auth.socialSignIn.cancelled",
                    },
                  } as AuthNavigateState,
                });
              }
            }
            break;
          case "signInWithRedirect_failure":
            if (
              payload?.message ===
              "PreSignUp+failed+with+error+Client+Metadata+was+undefined.+"
            ) {
              navigate(cognitoPaths.signUp, {
                replace: true,
                state: {
                  redirectUrl: "/",
                  message: {
                    level: AuthMessageLevel.ERROR,
                    tx: "auth.socialSignIn.notSignedUpYet",
                  },
                } as AuthNavigateState,
              });
            } else {
              navigate(cognitoPaths.magicSignIn, {
                replace: true,
                state: {
                  redirectUrl: "/",
                  message: {
                    level: AuthMessageLevel.ERROR,
                    tx: "auth.socialSignIn.customStateFailure",
                    txData: {
                      errorMessage: payload?.message,
                    },
                  },
                } as AuthNavigateState,
              });
            }
            break;
        }
      }),
    [navigate],
  );
  return null;
};
