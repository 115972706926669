"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.opadeStudy = void 0;
const cognito_1 = require("../../../model/cognito");
const legal_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/default-form-data-guards/legal-data-guard");
const social_demographics_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/default-form-data-guards/social-demographics-data-guard");
const updated_legal_policy_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/default-form-data-guards/updated-legal-policy-data-guard");
const form_data_guard_1 = require("../../../model/data-collection/guard/form-data-guard/form-data-guard");
const profile_inputs_1 = require("../../../model/data-collection/profile/profile-inputs");
const diseases_1 = require("../../../model/diseases");
const languages_1 = require("../../../model/languages");
const lock_1 = require("../../../model/lock/lock");
const pages_1 = require("../../../model/pages");
const deployment_environments_1 = require("../../deployment-environments");
exports.opadeStudy = {
    diseaseType: diseases_1.DiseaseType.STUDY,
    disease: diseases_1.Disease.OPADE,
    homePage: pages_1.FrontendPageEnum.CHAT,
    theme: {
        daisyTheme: {
            primary: "#3ca66a",
            "primary-focus": "#34915d",
            "primary-content": "#FFFFFF",
            secondary: "#88a9e3",
            "secondary-focus": "#7895c8",
            "secondary-content": "#FFFFFF",
            accent: "#3459A3",
            "accent-focus": "#2D4D8C",
            "accent-content": "#FFFFFF",
            neutral: "#424242",
            "neutral-focus": "#333333",
            "neutral-content": "#FFFFFF",
            "base-100": "#ffffff",
            "base-200": "#f0f0f0",
            "base-300": "#e0e0e0",
            "base-content": "#1f1f1f",
            info: "#25dbf1",
            "info-content": "#082f34",
            success: "#30ca6b",
            "success-content": "#14522a",
            warning: "#dec762",
            "warning-content": "#524b2b",
            error: "#ff4d4d",
            "error-content": "#ffffff",
            "--btn-text-case": "none",
        },
        extendedTheme: {
            colors: {
                mamaAskUserChatBubble: "#43d178",
                mamaAnswerUserChatBubble: "#1f3560",
            },
        },
    },
    supportedLanguages: [languages_1.Language.it_IT, languages_1.Language.es_ES, languages_1.Language.tr_TR],
    publicPages: [],
    enabledPages: [
        Object.assign({ txPageName: "chat", route: pages_1.FrontendPageEnum.CHAT, groupsWhichCanSeeThePage: [cognito_1.SupportedGroupsEnum.USER], settings: {
                chatType: pages_1.ChatType.LANDBOT,
                defaultLandbotUrlTx: "tenant.opade.defaultLandbotUrl",
            }, supportedLanguages: [languages_1.Language.it_IT, languages_1.Language.es_ES, languages_1.Language.tr_TR] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "home", route: "/home", groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], supportedLanguages: [languages_1.Language.it_IT, languages_1.Language.es_ES, languages_1.Language.tr_TR] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "journey", route: pages_1.FrontendPageEnum.JOURNEY, groupsWhichCanSeeThePage: [cognito_1.SupportedGroupsEnum.USER], supportedLanguages: [languages_1.Language.it_IT, languages_1.Language.es_ES, languages_1.Language.tr_TR] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "admin-signup", route: pages_1.FrontendPageEnum.ADMIN_SIGNUP, groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], supportedLanguages: [languages_1.Language.it_IT, languages_1.Language.es_ES, languages_1.Language.tr_TR] }, lock_1.defaultPageLock),
        Object.assign({ txPageName: "admin-dashboard", route: pages_1.FrontendPageEnum.ADMIN_DASHBOARD, groupsWhichCanSeeThePage: [cognito_1.SupportedGroupsEnum.ADMIN], settings: {
                dashboards: {
                    [deployment_environments_1.DeploymentEnvironment.DEV]: {
                        index: 21,
                    },
                    [deployment_environments_1.DeploymentEnvironment.PROD]: {
                        index: 21,
                    },
                },
            }, supportedLanguages: [languages_1.Language.it_IT, languages_1.Language.es_ES, languages_1.Language.tr_TR] }, lock_1.defaultPageLock),
        Object.assign({ route: "/language-notice", txPageName: "language-notice", groupsWhichCanSeeThePage: [
                cognito_1.SupportedGroupsEnum.USER,
                cognito_1.SupportedGroupsEnum.ADMIN,
            ], supportedLanguages: [languages_1.Language.en_US] }, lock_1.defaultPageLock),
    ],
    showCallSupportButton: {
        telephoneNumberTx: "disease.opade.settings.supportNumber",
    },
    authLogo: "/resources/major-depressive-disorder/opade/opade-logo.png",
    headerLogo: "/resources/major-depressive-disorder/opade/opade-logo.png",
    narrowHeaderLogo: "/resources/major-depressive-disorder/opade/opade-favicon.png",
    favIcon: "/resources/major-depressive-disorder/opade/opade-favicon.png",
    favIconHighres: "/resources/major-depressive-disorder/opade/opade-favicon.png",
    tenantNameTx: "disease.opade.settings.name",
    autoConfirmUsers: false,
    groupSecretsArns: {
        [deployment_environments_1.DeploymentEnvironment.DEV]: "arn:aws:secretsmanager:eu-central-1:616427543840:secret:group-secrets-opade-jCg5oq",
        [deployment_environments_1.DeploymentEnvironment.PROD]: "arn:aws:secretsmanager:eu-central-1:025928572003:secret:group-secrets-opade-hRt5wW",
    },
    identityProviders: [],
    metaDescriptionTx: "disease.opade.settings.description",
    organisations: [],
    dataGuards: {
        signUpAdmin: [legal_data_guard_1.legalDataGuard],
        existingAdmin: [updated_legal_policy_data_guard_1.updatedLegalPolicyDataGuard],
        signUpUser: [],
        existingUser: [
            (0, form_data_guard_1.insertInputs)(updated_legal_policy_data_guard_1.updatedLegalPolicyDataGuard, {
                type: form_data_guard_1.FormDataCollectionInputType.OPADE_PATIENT_ID,
            }),
            (0, social_demographics_data_guard_1.socialDemographicsDataGuard)([
                { type: form_data_guard_1.FormDataCollectionInputType.NAME },
                { type: form_data_guard_1.FormDataCollectionInputType.EMAIL },
                { type: form_data_guard_1.FormDataCollectionInputType.DATE_OF_BIRTH },
                {
                    type: form_data_guard_1.FormDataCollectionInputType.GENDER,
                    reasonWhyShown: "userData.biologicalSexNote",
                },
                { type: form_data_guard_1.FormDataCollectionInputType.COUNTRY },
                { type: form_data_guard_1.FormDataCollectionInputType.POSTAL_CODE },
                { type: form_data_guard_1.FormDataCollectionInputType.OPADE_PATIENT_ID },
                {
                    type: form_data_guard_1.FormDataCollectionInputType.ACQUISITION_DETAIL,
                    isOptional: true,
                },
                {
                    type: form_data_guard_1.FormDataCollectionInputType.PHONE_NUMBER,
                    reasonWhyShown: "userData.telephoneNote",
                    isOptional: true,
                },
            ]),
        ],
    },
    userFeedback: [],
    profileInputs: profile_inputs_1.opadeFormDataInputs,
    hideTutorial: true,
};
