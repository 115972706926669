/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const IeSubtype = {
    Acknowledge: 'ACKNOWLEDGE',
    Country: 'COUNTRY',
    Education: 'EDUCATION',
    Email: 'EMAIL',
    EndoStudyAgreement: 'ENDO_STUDY_AGREEMENT',
    Ethnicity: 'ETHNICITY',
    Final: 'FINAL',
    Gender: 'GENDER',
    Introduction: 'INTRODUCTION',
    Link: 'LINK',
    MarketingChannel: 'MARKETING_CHANNEL',
    MigrationBackground: 'MIGRATION_BACKGROUND',
    Name: 'NAME',
    Occupation: 'OCCUPATION',
    OpadeStudyId: 'OPADE_STUDY_ID',
    Pag: 'PAG',
    Phone: 'PHONE',
    PostalCode: 'POSTAL_CODE',
    Terms: 'TERMS',
    YearOfBirth: 'YEAR_OF_BIRTH'
} as const;
export type IeSubtype = typeof IeSubtype[keyof typeof IeSubtype];


export function IeSubtypeFromJSON(json: any): IeSubtype {
    return IeSubtypeFromJSONTyped(json, false);
}

export function IeSubtypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): IeSubtype {
    return json as IeSubtype;
}

export function IeSubtypeToJSON(value?: IeSubtype | null): any {
    return value as any;
}

