import React, { useCallback, useState } from "react";
import Cropper, { Area } from "react-easy-crop";
import { Control, Controller, FieldValues } from "react-hook-form";
import { PlainI18nProps } from "shared/types/i18n";
import { twMerge } from "tailwind-merge";

import { FormInput } from "./form-input";
import { useModal } from "../../models/modal-provider";

import "react-easy-crop/react-easy-crop.css";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormImageInputProps<T extends FieldValues = any> = {
  name: string;
  control: Control<T>;
  multiple?: boolean;
  disabled?: boolean;
  error: PlainI18nProps;
  children?: React.ReactNode;
  onCropComplete?: (images?: string[]) => void;
};

export const FormImageInput: React.FC<FormImageInputProps> = ({
  error,
  disabled,
  name,
  control,
  children,
  multiple = false,
  onCropComplete,
}) => {
  const cropImage = useImageCropper();
  const handleImageSelection = async (selectedImgs: Image[]) => {
    const croppedImages = [];

    for (const img of selectedImgs) {
      const croppedImage = await cropImage(img.image);
      croppedImage && croppedImages.push(croppedImage);
    }

    onCropComplete?.(croppedImages);
    return croppedImages;
  };

  return (
    <FormInput error={error}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <div className="container flex justify-center">
              <ImageInput
                onImageSelected={async (selectedImg) => {
                  const croppedImages = await handleImageSelection(selectedImg);
                  croppedImages.forEach(onChange);
                }}
                multiple={multiple}
                disabled={disabled}
              >
                {children}
              </ImageInput>
            </div>
          );
        }}
      />
    </FormInput>
  );
};

const useImageCropper = (): ((
  base64Image: string,
) => Promise<string | undefined>) => {
  const modal = useModal();

  return useCallback(
    (base64Image) =>
      new Promise((resolve) => {
        let finalImage = base64Image;
        modal.showModal({
          children: (
            <ImageCropper
              base64Image={base64Image}
              onCrop={(modifiedImage) => (finalImage = modifiedImage)}
            />
          ),
          onConfirm: () => {
            resolve(finalImage);
            modal.hideModal();
          },
          onClose: () => resolve(undefined),
        });
      }),
    [modal],
  );
};

const ImageCropper: React.FC<{
  base64Image: string;
  onCrop: (croppedBase64Image: string) => void;
}> = ({ base64Image, onCrop }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  return (
    <div className="cropper flex h-[400px] flex-col">
      <div className="relative h-full flex-1">
        <Cropper
          image={base64Image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropAreaChange={(_: Area, croppedAreaPixels: Area) =>
            cropImage(base64Image, croppedAreaPixels).then(onCrop)
          }
          style={{
            containerStyle: {
              width: "100%",
              backgroundColor: "#fff",
            },
          }}
        />
      </div>
    </div>
  );
};

const cropImage = (image: string, croppedArea: Area): Promise<string> =>
  new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    canvas.width = croppedArea.width;
    canvas.height = croppedArea.height;
    const context = canvas.getContext("2d");

    const croppedImage = new Image();
    croppedImage.src = image;

    croppedImage.onload = () => {
      context?.drawImage(
        croppedImage,
        croppedArea.x,
        croppedArea.y,
        croppedArea.width,
        croppedArea.height,
        0,
        0,
        croppedArea.width,
        croppedArea.height,
      );

      const dataURL = canvas.toDataURL("image/jpeg");

      resolve(dataURL);
    };
    croppedImage.onerror = reject;
  });

export interface Image {
  image: string;
  name: string;
}

export const ImageInput: React.FC<{
  multiple: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  onImageSelected: (r: Image[]) => void;
}> = ({
  onImageSelected,
  disabled,
  children,
  multiple,
  className,
  ...rest
}) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    async (event) => {
      if (event.target.files && event.target.files.length > 0) {
        const files = Array.from(event.target.files);
        const images: Image[] = [];

        for (const file of files) {
          await new Promise<void>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              const imageDataUrl = reader.result as string;
              images.push({ image: imageDataUrl, name: file.name });
              resolve();
            };
          });
        }

        onImageSelected(images);
      }
    },
    [onImageSelected],
  );

  return (
    <label
      className={twMerge(
        disabled ? "cursor-not-allowed" : "cursor-pointer",
        className,
      )}
    >
      <input
        type="file"
        accept="image/png, image/jpeg"
        multiple={multiple}
        disabled={disabled}
        onChange={handleChange}
        style={{ display: "none" }}
        {...rest}
      />
      <div>{children}</div>
    </label>
  );
};
