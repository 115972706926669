import { useEffect } from "react";
import { basePathOfTenant } from "shared/config/base-urls";

import { cognitoPaths } from "./paths";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { cleanUpState } from "../../../clean-up-state";

export const CognitoSessionCleanup: React.FC = () => {
  const tenantId = useTenantId();
  useEffect(() => {
    cleanUpState(basePathOfTenant(tenantId) + cognitoPaths.magicSignIn);
  }, [tenantId]);
  return null;
};
