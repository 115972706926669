/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageRecordWithType } from './MessageRecordWithType';
import {
    MessageRecordWithTypeFromJSON,
    MessageRecordWithTypeFromJSONTyped,
    MessageRecordWithTypeToJSON,
} from './MessageRecordWithType';

/**
 * 
 * @export
 * @interface PublicConversationRecordWithMessages
 */
export interface PublicConversationRecordWithMessages {
    /**
     * 
     * @type {string}
     * @memberof PublicConversationRecordWithMessages
     */
    id: string;
    /**
     * 
     * @type {Array<MessageRecordWithType>}
     * @memberof PublicConversationRecordWithMessages
     */
    messages: Array<MessageRecordWithType>;
    /**
     * 
     * @type {string}
     * @memberof PublicConversationRecordWithMessages
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof PublicConversationRecordWithMessages
     */
    inputTime: Date;
    /**
     * 
     * @type {string}
     * @memberof PublicConversationRecordWithMessages
     */
    type?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicConversationRecordWithMessages
     */
    isDone: boolean;
}

/**
 * Check if a given object implements the PublicConversationRecordWithMessages interface.
 */
export function instanceOfPublicConversationRecordWithMessages(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "messages" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "inputTime" in value;
    isInstance = isInstance && "isDone" in value;

    return isInstance;
}

export function PublicConversationRecordWithMessagesFromJSON(json: any): PublicConversationRecordWithMessages {
    return PublicConversationRecordWithMessagesFromJSONTyped(json, false);
}

export function PublicConversationRecordWithMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicConversationRecordWithMessages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'messages': ((json['messages'] as Array<any>).map(MessageRecordWithTypeFromJSON)),
        'userId': json['userId'],
        'inputTime': (new Date(json['inputTime'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'isDone': json['is_done'],
    };
}

export function PublicConversationRecordWithMessagesToJSON(value?: PublicConversationRecordWithMessages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'messages': ((value.messages as Array<any>).map(MessageRecordWithTypeToJSON)),
        'userId': value.userId,
        'inputTime': (value.inputTime.toISOString()),
        'type': value.type,
        'is_done': value.isDone,
    };
}

