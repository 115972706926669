import React, { useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  FormDataCollectionInputType,
  FormDataGuard,
  FormDataGuardLayoutType,
} from "shared/model/data-collection/guard/form-data-guard/form-data-guard";
import { Language } from "shared/model/languages";

import { acquisitionDetailInput } from "./inputs/acquisition-detail-input";
import { acquisitionSourceInput } from "./inputs/acquisition-source-input";
import { addressInput } from "./inputs/address-input";
import { biologicalSexInput } from "./inputs/biological-sex-input";
import { careProviderInput } from "./inputs/care-provider-input";
import { cityInput } from "./inputs/city-input";
import { conditionStartDateInput } from "./inputs/condition-start-date-input";
import { conditionVariantInput } from "./inputs/condition-variant-input";
import { countryInput } from "./inputs/country-input";
import { emailInput } from "./inputs/email-input";
import { endoStudyInformation20240115Input } from "./inputs/endo-study-information-2024-01-15-input";
import { languageInput } from "./inputs/language-input";
import { medicationsInput } from "./inputs/medications-input";
import { nameInput } from "./inputs/name-input";
import { opadePatientIdInput } from "./inputs/opade-patient-id-input";
import { phoneNumberInput } from "./inputs/phone-number-input";
import { postalCodeInput } from "./inputs/postal-code-input";
import { privacyPolicy20231018Input } from "./inputs/privacy-policy-2023-10-18-input";
import { profileImageInput } from "./inputs/profile-image-input";
import { termsAndConditions20230830Input } from "./inputs/terms-and-conditions-2023-08-30-input";
import { yearOfBirthInput } from "./inputs/year-of-birth-input";
import { existingUserLayout } from "./layouts/existing-user-layout";
import { signUpLayout } from "./layouts/sign-up-layout";
import {
  FormDataCollectionInput,
  FormDataGuardLayout,
  UpdateUserProfile,
} from "./types";
import {
  GroupInfoDtoGroupEnum,
  MultipleMembershipsDto,
  UpdateSettingsDto,
  UpdateUserAttributesDto,
  UpdateUserDetailsDto,
  User,
} from "../../../../api/generated/backend";
import { toTenantIdHeader, useApiMutation } from "../../../../api/use-api";
import { Form } from "../../../../components/form/form";
import { LoadingScreen } from "../../../../components/loading-screen";
import { I18nProps } from "../../../../components/text";
import { queryClient } from "../../../../query-client";
import { ME_KEY } from "../../../../types/query-keys";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { useUser } from "../../../../util/use-user";
import { DataGuardImplementation } from "../types";

type InputFieldType = {
  input: FormDataCollectionInput;
  reasonWhyShown?: I18nProps;
  prefilledValue?: I18nProps;
}[];

export const formDataGuardImplementation: DataGuardImplementation = {
  useWasCompleted: (config) => {
    const user = useUser();

    if (!user) {
      return undefined;
    }

    const {
      i18n: { language },
    } = useTranslation();

    const inputs = (config as FormDataGuard).inputs.filter(
      (input) =>
        !input.supportedLanguages ||
        input.supportedLanguages?.includes(language as Language),
    );

    return inputs.every(
      (input) =>
        formDataCollectionInputLookup[input.type].wasCompleted(user) ||
        input.isOptional,
    );
  },
  Component: ({ config }) => {
    const user = useUser();
    if (!user) {
      return <LoadingScreen />;
    }
    return (
      <FormDataGuardComponent config={config as FormDataGuard} user={user} />
    );
  },
};
const FormDataGuardComponent: React.FC<{
  config: FormDataGuard;
  user: User;
}> = ({ config, user }) => {
  const tenantId = useTenantId();
  const [searchParams] = useSearchParams();
  const queryLanguage = searchParams.get("language");
  const {
    i18n: { language },
  } = useTranslation();

  const appLanguage = useMemo(
    () => (!!queryLanguage ? queryLanguage : language),
    [language, queryLanguage],
  );

  const layout = formDataGuardLayouts[config.layout];
  const inputs = config.inputs
    .map((input) =>
      !input.supportedLanguages ||
      input.supportedLanguages?.includes(appLanguage as Language)
        ? {
            input: formDataCollectionInputLookup[input.type],
            reasonWhyShown: { tx: input.reasonWhyShown },
            prefilledValue: input.prefilledValue,
          }
        : undefined,
    )
    .filter((input) => input) as InputFieldType;

  const formMethods = useForm<UpdateUserProfile>({
    defaultValues: {
      ...user.userAttributes,
      ...user.userDetails,
      ...user.settings,
      dateOfBirth: getYearFromDate(user.userAttributes?.dateOfBirth),
      pags: user.organisationMemberGroups.map((group) => group.organisation),
    },
  });

  const { errors, isSubmitting } = formMethods.formState;

  const onSuccessfullSubmission = useCallback(() => {
    if (config.currentGuard !== undefined) {
      config.goToGuard?.(config.currentGuard + 1);
    }
  }, [config]);

  const { mutate: updateUserAttributes } = useApiMutation(
    "backend",
    (api) => (updateUserAttributesDto: UpdateUserAttributesDto) =>
      api.updateUserAttributes({ updateUserAttributesDto }),
    undefined,
    { successMessage: { tx: "profile.updateSuccessful" }, trackErrors: true },
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
      },
    },
  );

  const { mutate: updateUserDetails } = useApiMutation(
    "backend",
    (api) => (updateUserDetailsDto: UpdateUserDetailsDto) =>
      api.updateUserDetails({
        updateUserDetailsDto,
        ...toTenantIdHeader(tenantId),
      }),
    undefined,
    undefined,
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
      },
    },
  );

  const { mutate: updateUserSettings } = useApiMutation(
    "backend",
    (api) => (updateSettingsDto: UpdateSettingsDto) =>
      api.updateSettings({
        updateSettingsDto,
        ...toTenantIdHeader(tenantId),
      }),
    undefined,
    undefined,
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
      },
    },
  );

  const { mutate: updateOrganisationMemeberships } = useApiMutation(
    "backend",
    (api) => (multipleMembershipsDto: MultipleMembershipsDto) =>
      api.updateOrganisationMemeberships({
        multipleMembershipsDto,
        ...toTenantIdHeader(tenantId),
      }),
    undefined,
    undefined,
    {
      onSuccess() {
        queryClient.invalidateQueries(ME_KEY(tenantId));
      },
    },
  );

  return (
    <FormProvider {...formMethods}>
      <Form
        onSubmit={formMethods.handleSubmit((data: UpdateUserProfile) => {
          onSuccessfullSubmission();
          updateUserAttributes({
            ...data,
            dateOfBirth: getDateFromYear(data.dateOfBirth),
          });
          updateUserDetails(data);
          updateUserSettings(data);
          updateOrganisationMemeberships({
            groupInfos: data.pags.map((pag) => ({
              organisation: pag,
              group: GroupInfoDtoGroupEnum.Member,
            })),
          });
        })}
        className="h-full w-full"
      >
        <layout.Layout
          control={formMethods.control}
          register={formMethods.register}
          setValue={formMethods.setValue}
          errors={errors}
          inputs={inputs}
          user={user}
          loading={isSubmitting}
          goToGuard={config.goToGuard}
          currentGuard={config.currentGuard}
        />
      </Form>
    </FormProvider>
  );
};

export const formDataCollectionInputLookup: Record<
  FormDataCollectionInputType,
  FormDataCollectionInput
> = {
  [FormDataCollectionInputType.NAME]: nameInput,
  [FormDataCollectionInputType.EMAIL]: emailInput,
  [FormDataCollectionInputType.PRIVACY_POLICY_2023_10_18]:
    privacyPolicy20231018Input,
  [FormDataCollectionInputType.TERMS_AND_CONDITIONS_2023_08_30]:
    termsAndConditions20230830Input,
  [FormDataCollectionInputType.ENDO_STUDY_INFORMATION_2024_01_15]:
    endoStudyInformation20240115Input,
  [FormDataCollectionInputType.OPADE_PATIENT_ID]: opadePatientIdInput,
  [FormDataCollectionInputType.LANGUAGE]: languageInput,
  [FormDataCollectionInputType.DATE_OF_BIRTH]: yearOfBirthInput,
  [FormDataCollectionInputType.GENDER]: biologicalSexInput,
  [FormDataCollectionInputType.ADDRESS]: addressInput,
  [FormDataCollectionInputType.CITY]: cityInput,
  [FormDataCollectionInputType.COUNTRY]: countryInput,
  [FormDataCollectionInputType.POSTAL_CODE]: postalCodeInput,
  [FormDataCollectionInputType.ACQUISITION_SOURCE]: acquisitionSourceInput,
  [FormDataCollectionInputType.ACQUISITION_DETAIL]: acquisitionDetailInput,
  [FormDataCollectionInputType.MEDICATIONS]: medicationsInput,
  [FormDataCollectionInputType.CONDITION_START_DATE]: conditionStartDateInput,
  [FormDataCollectionInputType.CONDITION_VARIANT]: conditionVariantInput,
  [FormDataCollectionInputType.CARE_PROVIDER]: careProviderInput,
  [FormDataCollectionInputType.PHONE_NUMBER]: phoneNumberInput,
  [FormDataCollectionInputType.PROFILE_IMAGE]: profileImageInput,
};

const formDataGuardLayouts: Record<
  FormDataGuardLayoutType,
  FormDataGuardLayout
> = {
  [FormDataGuardLayoutType.EXISTING_USER_LAYOUT]: existingUserLayout,
  [FormDataGuardLayoutType.SIGN_UP_LAYOUT]: signUpLayout,
};

const getDateFromYear = (year?: string) => {
  return year ? new Date(Number(year), 0, 1).toISOString() : undefined;
};

const getYearFromDate = (date?: string) => {
  return date ? new Date(date).getFullYear().toString() : undefined;
};
