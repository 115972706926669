"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.opadeFormDataInputs = exports.baseFormDataInputs = exports.personalInfoFormDataInputs = void 0;
const form_data_guard_1 = require("../guard/form-data-guard/form-data-guard");
exports.personalInfoFormDataInputs = [
    form_data_guard_1.FormDataCollectionInputType.NAME,
    form_data_guard_1.FormDataCollectionInputType.EMAIL,
];
exports.baseFormDataInputs = [
    ...exports.personalInfoFormDataInputs,
    form_data_guard_1.FormDataCollectionInputType.LANGUAGE,
];
exports.opadeFormDataInputs = [
    ...exports.personalInfoFormDataInputs,
    form_data_guard_1.FormDataCollectionInputType.OPADE_PATIENT_ID,
    form_data_guard_1.FormDataCollectionInputType.COUNTRY,
];
