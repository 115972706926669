import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "shared/model/languages";
import { PlainI18nProps } from "shared/types/i18n";

export type T = (props?: PlainI18nProps, lng?: Language) => string;

export const useT = (): T => {
  const { t } = useTranslation();
  return useCallback(
    ({ tx, text, txData, txUnchecked } = {}, lng?: Language) => {
      const resolvedTx = tx || txUnchecked;
      return resolvedTx ? t(resolvedTx, { lng, ...txData }) : text ?? "";
    },
    [t],
  );
};
