import { MIN_PASSWORD_LENGTH } from "shared/config/cognito";
import { PlainI18nProps } from "shared/types/i18n";

export class MamaCognitoError extends Error {
  public constructor({ name, message }: { name: string; message?: string }) {
    super(message);
    this.name = name;
    Object.setPrototypeOf(this, MamaCognitoError.prototype);
  }
}

export interface CognitoError {
  name: string;
  message: string;
}

export const handleCognitoError = (err: unknown): PlainI18nProps => {
  return isCognitoError(err)
    ? returnLocalizedCognitoError(err.name, err.message)
    : { txUnchecked: "auth.signIn.errors.UnknownError" };
};

export const isCognitoError = (error: unknown): error is CognitoError => {
  return (
    typeof error === "object" &&
    error !== null &&
    "name" in error &&
    "message" in error
  );
};

const returnLocalizedCognitoError = (
  errorName: string,
  errorMessage: string,
): PlainI18nProps => {
  if (errorName === "UserLambdaValidationException") {
    const split = errorMessage.split(" failed with error ");

    return split.length === 2
      ? {
          txUnchecked: `auth.signIn.errors.${split[1]}`,
          text: errorMessage,
        }
      : { text: errorMessage };
  }

  return {
    txUnchecked: `auth.signIn.errors.${errorName}`,
    txData: { min_password_length: MIN_PASSWORD_LENGTH },
    text: errorMessage,
  };
};
