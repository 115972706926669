import { useQueryClient } from "@tanstack/react-query";
import { LatLngExpression } from "leaflet";
import React, { useState } from "react";
import { TranslationFeKey } from "shared/types/translation-key";
import { twMerge } from "tailwind-merge";

import {
  DeleteDoctorRequest,
  Doctor,
  RateDoctorRequest,
} from "../../api/generated/backend";
import { useApiMutation } from "../../api/use-api";
import { useModal } from "../../models/modal-provider";
import { MY_DOCTORS, RATE_DOCTOR } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { PrimaryButton } from "../form/button";
import { Text } from "../text";

interface DoctorListProps {
  doctors?: Doctor[];
  onGoToButtonClick: (coords: LatLngExpression) => void;
  onDeleteButtonClick: (request: DeleteDoctorRequest) => void;
}

interface DoctorItemProps extends Omit<DoctorListProps, "doctor"> {
  doctor: Doctor;
}

export const DoctorList: React.FC<DoctorListProps> = ({
  doctors,
  onGoToButtonClick,
  onDeleteButtonClick,
}) => {
  const [isListHidden, setIsListHidden] = useState(true);

  return (
    <div
      className={twMerge(
        "absolute bottom-0 right-0 z-[10004] m-2 flex w-full max-w-sm flex-col justify-end gap-2",
        isListHidden ? "h-0" : "h-full",
      )}
    >
      {!isListHidden && (
        <div className="mt-8 flex h-full flex-col overflow-scroll rounded-xl border-4 p-4 backdrop-blur-sm">
          {doctors?.length ? (
            doctors?.map((doctor) => (
              <DoctorItem
                key={doctor.id}
                doctor={doctor}
                onGoToButtonClick={onGoToButtonClick}
                onDeleteButtonClick={onDeleteButtonClick}
              />
            ))
          ) : (
            <div className="flex h-full items-center justify-center">
              <Text
                tx="networkBuilder.noDoctorsInYourNetwork"
                className="items-center justify-center self-center text-center text-2xl"
              />
            </div>
          )}
        </div>
      )}
      <PrimaryButton
        className="w-fit self-end"
        tx={isListHidden ? "networkBuilder.myNetwork" : "general.close"}
        onClick={() => {
          setIsListHidden(!isListHidden);
        }}
      />
    </div>
  );
};

const DoctorItem: React.FC<DoctorItemProps> = ({
  doctor,
  onGoToButtonClick,
  onDeleteButtonClick,
}) => {
  const { showModal, hideModal } = useModal();

  return (
    <div className="flex flex-col border-b-2 border-zinc-400 p-4">
      <p className="text-2xl">{doctor.doctorName}</p>
      {doctor.isLinked && <DoctorRating doctor={doctor} />}
      <p className="mt-2 text-lg">{doctor.streetAddress}</p>
      <Text
        tx={
          `networkBuilder.${doctor.generalMedicalSpeciality}` as TranslationFeKey
        }
      />
      <div className="flex gap-5">
        <Text
          tx="networkBuilder.goToLocation"
          className="cursor-pointer text-secondary"
          onClick={() => {
            onGoToButtonClick([doctor.lat, doctor._long]);
          }}
        />
        <Text
          tx="networkBuilder.remove"
          className="cursor-pointer text-error"
          onClick={() => {
            showModal({
              title: { tx: "networkBuilder.deletePin" },
              description: {
                tx: "networkBuilder.areYouSureYouWantToDeleteThisDoctor",
              },
              onConfirm: () => {
                hideModal();
                onDeleteButtonClick({
                  deleteDoctorDto: {
                    doctorId: doctor.id,
                    isLinked: doctor.isLinked ?? false,
                  },
                });
              },
            });
          }}
        />
      </div>
    </div>
  );
};

const MAXIMUM_STAR_RATING = 5;
const DoctorRating: React.FC<Pick<DoctorItemProps, "doctor">> = ({
  doctor,
}) => {
  const tenandId = useTenantId();
  const queryClient = useQueryClient();

  const [userRating, setUserRating] = useState(Math.round(doctor.rating ?? 0));

  const { mutate: rateDoctor } = useApiMutation(
    "backend",
    (api) => (request: RateDoctorRequest) => api.rateDoctor(request),
    RATE_DOCTOR,
    { successMessage: { tx: "networkBuilder.ratingSubmitted" } },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(MY_DOCTORS(tenandId.disease));
      },
    },
  );

  return (
    <div className="-ml-2 flex flex-col">
      <div className="rating">
        {Array.from(new Array(MAXIMUM_STAR_RATING + 1)).map((_, idx) => (
          <input
            key={idx}
            type="radio"
            className={`mask mask-star-2 bg-secondary ${
              idx === 0 && "rating-hidden pointer-events-none"
            }`}
            checked={userRating === idx}
            onChange={() => {
              setUserRating(idx);
              rateDoctor({
                rateDoctorDto: { doctorId: doctor.id, rating: idx },
              });
            }}
          />
        ))}
      </div>
    </div>
  );
};
