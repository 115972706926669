import React from "react";
import {
  Control,
  Controller,
  Path,
  PathValue,
  Validate,
} from "react-hook-form";
import { PlainI18nProps } from "shared/types/i18n";

import { SupportedLanguagesDropdown } from "./supported-languages-dropdown";
import { FormInput } from "../form-input";

export type FormLanguageDropDownProps<
  Values extends object,
  Name extends Path<Values>,
> = {
  name: Name;
  title?: PlainI18nProps;
  error: PlainI18nProps;
  control: Control<Values>;
  labelClassName?: string;
  className?: string;
  selectedValueClassName?: string;
  validate?: Validate<PathValue<Values, Name>, Values>;
};

export const FormLanguageDropDown = <
  Values extends object,
  Name extends Path<Values>,
>({
  title,
  error,
  name,
  control,
  validate,
  className,
  labelClassName,
  selectedValueClassName,
}: FormLanguageDropDownProps<Values, Name>): ReturnType<React.FC> => {
  return (
    <FormInput title={title} error={error} labelClassName={labelClassName}>
      <Controller
        name={name}
        control={control}
        rules={{
          validate,
        }}
        render={({ field: { onChange, value } }) => (
          <SupportedLanguagesDropdown
            className={className}
            value={value}
            onChange={onChange}
            selectedValueClassName={selectedValueClassName}
          />
        )}
      />
    </FormInput>
  );
};
