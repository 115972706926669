import { TranslationFeKey } from "shared/types/translation-key";

import { FormTextInput } from "../../../../../components/form/form-text-input";
import { FormDataCollectionInput } from "../types";

export const careProviderInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userDetails?.careProvider,
  Component: ({ errors, register }) => (
    <FormTextInput
      labelClassName="font-bold"
      title={{ tx: "profile.careProvider" }}
      {...register("careProvider")}
      error={{ tx: errors.careProvider?.message as TranslationFeKey }}
    />
  ),
};
