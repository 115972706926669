import React from "react";
import { Control, Controller, Path } from "react-hook-form";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { PlainI18nProps, tx } from "shared/types/i18n";
import { twMerge } from "tailwind-merge";

import { FormInput } from "./form-input";
import { UpdateUserAttributesDto } from "../../api/generated/backend";

import "react-phone-number-input/style.css";

export type FormTelephoneInputProps<
  Values extends Pick<UpdateUserAttributesDto, "telephone">,
> = {
  title: PlainI18nProps;
  error: PlainI18nProps;
  disabled?: boolean;
  name: Path<Values>;
  control: Control<Values>;
  required?: boolean;
  labelClassName?: string;
  isOptional?: boolean;
};

export const FormTelephoneInput = <
  Values extends Pick<UpdateUserAttributesDto, "telephone">,
>({
  title,
  error,
  disabled,
  name,
  control,
  required,
  labelClassName,
  isOptional,
}: FormTelephoneInputProps<Values>): ReturnType<React.FC> => {
  return (
    <FormInput
      title={title}
      error={error}
      isOptional={isOptional}
      labelClassName={labelClassName}
    >
      <Controller
        name={name}
        control={control}
        rules={{
          validate: (value) => {
            if (!value?.trim()) {
              if (required) {
                return tx("profile.phoneNumberRequiredError");
              }
              return;
            }
            if (!isPossiblePhoneNumber(value) || !isValidPhoneNumber(value)) {
              return tx("profile.notAValidPhoneNumber");
            }
          },
        }}
        render={({ field: { onChange, value } }) => (
          <PhoneInput
            numberInputProps={{
              className: twMerge(
                "input-bordered input",
                "w-full",
                disabled ? "cursor-not-allowed text-gray-700" : "",
              ),
            }}
            countrySelectProps={{
              className: twMerge(
                "input-bordered input",
                "w-full",
                disabled ? "cursor-not-allowed text-gray-700" : "",
              ),
            }}
            disabled={disabled}
            value={value ?? undefined}
            onChange={(lValue) => onChange(lValue ?? undefined)}
            id={name}
          />
        )}
      />
    </FormInput>
  );
};
