import { tx } from "shared/types/i18n";

import { useObservedUserData } from "../../../../../auth/auth-store-context";
import { FormTextInput } from "../../../../../components/form/form-text-input";
import { isEmailRegex } from "../../../../../util/regex";
import { FormDataCollectionInput } from "../types";

export const emailInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.email,
  Component: ({ errors, register, user }) => {
    const userData = useObservedUserData();

    const showEmailInput =
      (!user.userAttributes?.email && !user.userAttributes?.finishedSignUp) ||
      user.userAttributes.finishedSignUp;

    return showEmailInput ? (
      <FormTextInput
        labelClassName="font-bold"
        defaultValue={userData?.email}
        title={{ tx: "auth.signUp.inputs.email.title" }}
        disabled={!!userData?.email && !user.userAttributes?.email}
        placeholder={{ tx: "auth.signUp.inputs.email.placeholder" }}
        autoComplete="username"
        {...register("email", {
          required: tx("auth.signUp.inputs.email.fieldMissingError"),
          pattern: {
            value: isEmailRegex,
            message: tx("auth.signUp.inputs.email.notAnEmailError"),
          },
          value: userData?.email,
        })}
        error={{ txUnchecked: errors.email?.message }}
      />
    ) : null;
  },
};
