import React from "react";
import { PlainI18nProps } from "shared/types/i18n";
import { twMerge } from "tailwind-merge";

import { i18nPropsHaveContent } from "../../i18n/i18n-props-have-content";
import { useT } from "../../i18n/use-t";

export const FormInput: React.FC<
  React.PropsWithChildren<{
    error: PlainI18nProps;
    title?: PlainI18nProps;
    labelClassName?: string;
    isOptional?: boolean;
  }>
> = ({ error, title, children, labelClassName, isOptional }) => {
  const t = useT();
  return (
    <div
      className={twMerge(
        `form-control mb-2 w-full rounded-lg ${
          i18nPropsHaveContent(error) ? "bg-error bg-opacity-25" : ""
        }`,
      )}
    >
      <div className="px-2 pb-2">
        {i18nPropsHaveContent(title) && (
          <label className="label">
            <span className={twMerge("bold label-text py-2", labelClassName)}>
              {i18nPropsHaveContent(title) && t(title)}
              {isOptional && (
                <span className="font-normal">{` (${t({
                  tx: "general.optional",
                })})`}</span>
              )}
              {i18nPropsHaveContent(title) &&
                i18nPropsHaveContent(error) &&
                " - "}
              {i18nPropsHaveContent(error) && t(error)}
            </span>
          </label>
        )}
        {children}
      </div>
    </div>
  );
};
