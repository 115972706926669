import { QueryClientProvider } from "@tanstack/react-query";
import React, { Suspense, useRef, useState } from "react";
import { Outlet } from "react-router-dom";

import { AuthStateStoreProvider } from "../auth/auth-state-store-provider";
import { AuthStoreProvider } from "../auth/auth-store-provider";
import { useCognitoAutoSignIn } from "../auth/implementations/cognito/hooks/use-cognito-auto-sign-in";
import { CreateUserGuard } from "../components/create-user-guard";
import { Events } from "../components/events/events";
import { ForwardEndoToEndometriosis } from "../components/forward-endo-to-endometriosis";
import { HasEnabledPages } from "../components/has-enabled-pages";
import { IdentifyUserInGoogleAnalytics } from "../components/identify-user-in-google-analytics";
import { LinkSentryToUser } from "../components/link-sentry-to-user";
import { DataGuard } from "../data-collection/guard/data-guard";
import { DynamicHelmet } from "../dynamic-helmet";
import { EnsureUserFollowsTenantGuard } from "../guards/ensure-user-follows-tenant-guard";
import {
  EnsureLanguageIsSupported,
  SynchronizeLanguageWithBackend,
} from "../i18n/synchronize-language-with-backend";
import { LanguageSettings } from "../language-settings";
import { StoreProvider } from "../models/helpers";
import { ModalProvider } from "../models/modal-provider";
import { RootStore } from "../models/root";
import { useLastActiveTenantSaver } from "../navigate-to-last-active-tenant";
import { queryClient } from "../query-client";
import { ThemeProvider } from "../theme-provider";

export const Root: React.FC = () => {
  const [i18NReady, setI18NReady] = useState(false);
  const rootStoreRef = useRef<RootStore>(new RootStore());

  return (
    <QueryClientProvider client={queryClient}>
      <AuthStateStoreProvider>
        <StoreProvider value={rootStoreRef.current}>
          {i18NReady ? (
            <Suspense>
              <Outlet />
            </Suspense>
          ) : null}
          <ForwardEndoToEndometriosis />
          <LanguageSettings setI18NReady={setI18NReady} />
          <Events />
        </StoreProvider>
      </AuthStateStoreProvider>
    </QueryClientProvider>
  );
};

export const TenantRoot: React.FC = () => {
  useLastActiveTenantSaver(false);
  return (
    <ThemeProvider>
      <DynamicHelmet />
      <AuthStoreProvider>
        <CreateUserGuard>
          <LinkSentryToUser />
          <ModalProvider>
            <SynchronizeLanguageWithBackend />
            <IdentifyUserInGoogleAnalytics />
            <EnsureUserFollowsTenantGuard>
              <HasEnabledPages>
                <DataGuard>
                  <Outlet />
                </DataGuard>
              </HasEnabledPages>
            </EnsureUserFollowsTenantGuard>
          </ModalProvider>
        </CreateUserGuard>
      </AuthStoreProvider>
    </ThemeProvider>
  );
};

export const TenantPublicRoot: React.FC = () => {
  useLastActiveTenantSaver(false);
  useCognitoAutoSignIn();
  return (
    <ThemeProvider>
      <ModalProvider>
        <EnsureLanguageIsSupported />
        <Outlet />
      </ModalProvider>
    </ThemeProvider>
  );
};
