import React from "react";
import {
  Control,
  Controller,
  Path,
  PathValue,
  Validate,
} from "react-hook-form";
import { PlainI18nProps } from "shared/types/i18n";
import { twMerge } from "tailwind-merge";

import { Dropdown, DropdownProps } from "./dropdown";
import { FormInput } from "./form-input";

export type FormDropdownInputProps<
  Values extends object,
  Name extends Path<Values>,
> = {
  title: PlainI18nProps;
  error: PlainI18nProps;
  disabled?: boolean;
  name: Name;
  control: Control<Values>;
  defaultValue?: PathValue<Values, Name>;
  options: DropdownProps<NonNullable<PathValue<Values, Name>>>["options"];
  noValueSelected: DropdownProps<PathValue<Values, Name>>["noValueSelected"];
  validate?: Validate<PathValue<Values, Name>, Values>;
  className?: string;
  labelClassName?: string;
};

export const FormDropdownInput = <
  Values extends object,
  Name extends Path<Values>,
>({
  title,
  error,
  disabled,
  name,
  control,
  options,
  noValueSelected,
  validate,
  className,
  labelClassName,
  defaultValue,
}: FormDropdownInputProps<Values, Name>): ReturnType<React.FC> => {
  return (
    <FormInput title={title} error={error} labelClassName={labelClassName}>
      <Controller
        name={name}
        control={control}
        rules={{
          validate,
        }}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <Dropdown
            selectedValueClassName="font-normal"
            className={twMerge(className, "w-full")}
            disabled={disabled}
            value={value}
            onChange={(lValue) => onChange(lValue ?? "")}
            noValueSelected={noValueSelected}
            options={options}
          />
        )}
      />
    </FormInput>
  );
};
