/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionNode } from './QuestionNode';
import {
    QuestionNodeFromJSON,
    QuestionNodeFromJSONTyped,
    QuestionNodeToJSON,
} from './QuestionNode';
import type { QuestionStatus } from './QuestionStatus';
import {
    QuestionStatusFromJSON,
    QuestionStatusFromJSONTyped,
    QuestionStatusToJSON,
} from './QuestionStatus';
import type { QuestionnaireState } from './QuestionnaireState';
import {
    QuestionnaireStateFromJSON,
    QuestionnaireStateFromJSONTyped,
    QuestionnaireStateToJSON,
} from './QuestionnaireState';

/**
 * Represents a QuestionVisit record
 * @export
 * @interface QuestionVisit
 */
export interface QuestionVisit {
    /**
     * 
     * @type {string}
     * @memberof QuestionVisit
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof QuestionVisit
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof QuestionVisit
     */
    nodeId: string;
    /**
     * 
     * @type {QuestionNode}
     * @memberof QuestionVisit
     */
    questionNode?: QuestionNode;
    /**
     * 
     * @type {string}
     * @memberof QuestionVisit
     */
    questionNodeId?: string;
    /**
     * 
     * @type {QuestionStatus}
     * @memberof QuestionVisit
     */
    status: QuestionStatus;
    /**
     * 
     * @type {string}
     * @memberof QuestionVisit
     */
    nodeResponseSummary: string;
    /**
     * 
     * @type {QuestionnaireState}
     * @memberof QuestionVisit
     */
    questionnaireState?: QuestionnaireState;
    /**
     * 
     * @type {string}
     * @memberof QuestionVisit
     */
    questionnaireStateId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionVisit
     */
    assistantMessageId?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionVisit
     */
    userMessageId?: string;
}

/**
 * Check if a given object implements the QuestionVisit interface.
 */
export function instanceOfQuestionVisit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "nodeId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "nodeResponseSummary" in value;
    isInstance = isInstance && "questionnaireStateId" in value;

    return isInstance;
}

export function QuestionVisitFromJSON(json: any): QuestionVisit {
    return QuestionVisitFromJSONTyped(json, false);
}

export function QuestionVisitFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuestionVisit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'nodeId': json['nodeId'],
        'questionNode': !exists(json, 'questionNode') ? undefined : QuestionNodeFromJSON(json['questionNode']),
        'questionNodeId': !exists(json, 'questionNodeId') ? undefined : json['questionNodeId'],
        'status': QuestionStatusFromJSON(json['status']),
        'nodeResponseSummary': json['nodeResponseSummary'],
        'questionnaireState': !exists(json, 'questionnaireState') ? undefined : QuestionnaireStateFromJSON(json['questionnaireState']),
        'questionnaireStateId': json['questionnaireStateId'],
        'assistantMessageId': !exists(json, 'assistantMessageId') ? undefined : json['assistantMessageId'],
        'userMessageId': !exists(json, 'userMessageId') ? undefined : json['userMessageId'],
    };
}

export function QuestionVisitToJSON(value?: QuestionVisit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'nodeId': value.nodeId,
        'questionNode': QuestionNodeToJSON(value.questionNode),
        'questionNodeId': value.questionNodeId,
        'status': QuestionStatusToJSON(value.status),
        'nodeResponseSummary': value.nodeResponseSummary,
        'questionnaireState': QuestionnaireStateToJSON(value.questionnaireState),
        'questionnaireStateId': value.questionnaireStateId,
        'assistantMessageId': value.assistantMessageId,
        'userMessageId': value.userMessageId,
    };
}

