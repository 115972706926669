import { StripeConnectAccountButton } from "./stripe-connect-account-button";
import { StripeCreateAccountButton } from "./stripe-create-account-button";
import { Text } from "../text";

export const StripeCreateOrLinkAccount: React.FC = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="flex max-w-lg flex-col gap-5">
        <Text
          className="max-w-xl text-2xl"
          tx="stripe.noStripeAccountConnected"
        />
        <Text tx="stripe.noStripeAccount" className="-mb-3" />
        <StripeCreateAccountButton />
        <Text tx="stripe.haveStripeAccount" className="-mb-3" />
        <StripeConnectAccountButton />
      </div>
    </div>
  );
};
