import { FrontendPageEnum } from "shared/model/pages";

import { useT } from "../../../../../../i18n/use-t";
import { useNavigateInsideTenant } from "../../../../../../util/navigation-hooks";
import { GhostButton, PrimaryButton } from "../../../../../form/button";
import { ChatOptionComponentProps } from "../lookup";

export const CheckOutMamaAnswerMultipleChoice: React.FC<
  ChatOptionComponentProps
> = ({ sendResponse }) => {
  const t = useT();
  const navigate = useNavigateInsideTenant();
  return (
    <div className="flex flex-row justify-center gap-2">
      <GhostButton
        tx="chat.stayHere"
        onClick={() => {
          sendResponse?.({
            message: t({ tx: "chat.stayHere" }),
            selection: [FrontendPageEnum.CHAT],
          });
        }}
      />
      <GhostButton
        tx="chat.alreadySawIt"
        onClick={() => {
          sendResponse?.({
            message: t({ tx: "chat.alreadySawIt" }),
            selection: [FrontendPageEnum.CHAT],
          });
        }}
      />
      <PrimaryButton
        tx="chat.seeMamaAnswer"
        onClick={() => {
          navigate(FrontendPageEnum.MAMA_ANSWER);
        }}
      />
    </div>
  );
};
