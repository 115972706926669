import { Gender } from "shared/model/user";
import { tx } from "shared/types/i18n";

import { FormDropdownInput } from "../../../../../components/form/form-dropdown-input";
import { getTextValue } from "../../../../../util/text";
import { FormDataCollectionInput } from "../types";

export const biologicalSexInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.gender,
  Component: ({ errors, control, prefilledValue }) => (
    <FormDropdownInput
      labelClassName="font-bold"
      className="input-bordered rounded-lg border-[1.5px]"
      title={{ tx: "userData.biologicalSex" }}
      error={{ txUnchecked: errors.gender?.message }}
      options={[
        {
          label: { tx: "userData.male" },
          value: Gender.MALE,
        },
        {
          label: { tx: "userData.female" },
          value: Gender.FEMALE,
        },
        {
          label: { tx: "userData.intersex" },
          value: Gender.INTERSEX,
        },
      ]}
      noValueSelected={{
        tx: "userData.selectBiologicalSex",
      }}
      name="gender"
      control={control}
      defaultValue={
        prefilledValue
          ? Gender[getTextValue(prefilledValue) as keyof typeof Gender]
          : undefined
      }
      validate={(value) => {
        if (!value) {
          return tx("userData.biologicalSexIsRequiredError");
        }
      }}
    />
  ),
};
