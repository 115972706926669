import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DeploymentEnvironment } from "shared/config/deployment-environments";

import { appEnv } from "./env-utils";

declare global {
  type DataLayerEntryType = { event: string; user_id?: string };
  interface Window {
    dataLayer: DataLayerEntryType[];
  }
}

export const userHasStartedSignup = (): void => {
  if (!doesEventExist("signup_started")) {
    pushToDataLayer({ event: "signup_started" });
  }
};

export const userHasCreatedAccount = (): void => {
  if (!doesEventExist("account_created")) {
    pushToDataLayer({ event: "account_created" });
  }
};

export const userHasConfirmedAccount = (): void => {
  if (!doesEventExist("account_confirmed")) {
    pushToDataLayer({ event: "account_confirmed" });
  }
};

export const userHasFinishedSignup = (): void => {
  if (!doesEventExist("signup_finished")) {
    pushToDataLayer({ event: "signup_finished" });
  }
};

export const useUserHasInteractedWithSignupForm = (isDirty: boolean): void => {
  const { hash } = useLocation();
  useEffect(() => {
    if (
      isDirty &&
      !hash.includes("#test") &&
      !doesEventExist("signup_form_touched")
    ) {
      pushToDataLayer({ event: "signup_form_touched" });
    }
  }, [hash, isDirty]);
};

export const identifyUserBasedOnId = (id: string): void => {
  if (!doesEventExist("user_id")) {
    pushToDataLayer({
      event: "user_id",
      user_id: id,
    });
  }
};

const doesEventExist = (event: string) => {
  return window.dataLayer?.some((dl) => dl.event === event);
};

const pushToDataLayer = (dataLayerEntry: DataLayerEntryType) => {
  if (appEnv === DeploymentEnvironment.PROD) {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push(dataLayerEntry);
  }
};
