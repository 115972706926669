import { useQueryClient } from "@tanstack/react-query";
import { Popup } from "react-leaflet";
import { MedicalSpecialty } from "shared/types/medical-specialty";

import {
  DeleteDoctorRequest,
  DoctorGeneralMedicalSpecialityEnum,
  LinkDoctorRequest,
} from "../../api/generated/backend";
import { useApiMutation } from "../../api/use-api";
import { LINK_DOCTOR, MY_DOCTORS } from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { Link } from "../link";
import { I18nProps, Text } from "../text";

export const PinPopup: React.FC<{
  id: string;
  title?: I18nProps;
  isLinked: boolean;
  phoneNumbers?: string[];
  description?: I18nProps;
  googleMapsLink?: string;
  children?: React.ReactNode;
  showLinkDoctorButton?: boolean;
  type: DoctorGeneralMedicalSpecialityEnum;
  onLinkDoctor?: () => void;
  onDeleteDoctor?: () => void;
}> = ({
  id,
  type,
  title,
  children,
  isLinked,
  description,
  phoneNumbers,
  googleMapsLink,
  showLinkDoctorButton,
  onLinkDoctor,
  onDeleteDoctor,
}) => {
  const queryClient = useQueryClient();
  const tenandId = useTenantId();

  const { mutate: linkDoctor } = useApiMutation(
    "backend",
    (api) => (request: LinkDoctorRequest) => api.linkDoctor(request),
    LINK_DOCTOR(tenandId.disease),
    undefined,
    {
      onSuccess: () => {
        onLinkDoctor?.();
        queryClient.invalidateQueries(MY_DOCTORS(tenandId.disease));
      },
    },
  );

  const { mutate: deleteDoctor } = useApiMutation(
    "backend",
    (api) => (request: DeleteDoctorRequest) => api.deleteDoctor(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        onDeleteDoctor?.();
        queryClient.invalidateQueries(MY_DOCTORS(tenandId.disease));
      },
    },
  );

  return (
    <Popup
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{
        borderWidth: "3px",
        borderColor:
          type === MedicalSpecialty.Ms
            ? "#d37184"
            : type === MedicalSpecialty.Neurologie
            ? "#7acbe0"
            : type === MedicalSpecialty.Allgemeinmedizin
            ? "#43d179"
            : type === MedicalSpecialty.Physiotherapeut
            ? "#b8d15d"
            : "black",
        borderRadius: "13px",
      }}
    >
      <div className="flex w-full flex-col gap-2">
        <Text className="flex-1 font-bold" {...title} />

        {type && (
          <Text
            className="-mt-2 font-bold text-secondary"
            tx={`networkBuilder.${type}`}
          />
        )}
        <div>
          <Text className="text-xs" {...description} />
          <div className="mt-2 flex gap-2">
            {phoneNumbers?.map((number) => (
              <a
                key={number}
                href={`tel:${number}`}
                className="cursor-pointer text-xs"
              >
                {number}
              </a>
            ))}
          </div>
        </div>
        {googleMapsLink && (
          <Link target="_blank" className="max-w-fit" href={googleMapsLink}>
            <Text tx="networkBuilder.directions" />
          </Link>
        )}
        {children}
      </div>
      {!showLinkDoctorButton ? (
        <div
          className="mt-2 cursor-pointer font-bold "
          onClick={() => {
            linkDoctor({ linkDoctorDto: { doctorId: id } });
          }}
        >
          <Text className="font-bold" tx="networkBuilder.saveToMyNetwork" />
        </div>
      ) : (
        <div
          className="mt-2 cursor-pointer font-bold "
          onClick={() => {
            deleteDoctor({ deleteDoctorDto: { doctorId: id, isLinked } });
          }}
        >
          <Text
            className="font-bold text-error"
            tx="networkBuilder.removeFromNetwork"
          />
        </div>
      )}
    </Popup>
  );
};
