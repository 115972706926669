import { TranslationFeKey } from "shared/types/translation-key";

import { FormTelephoneInput } from "../../../../../components/form/form-telephone-input";
import { FormDataCollectionInput } from "../types";

export const phoneNumberInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.telephone,
  Component: ({ errors, control }) => (
    <FormTelephoneInput
      isOptional
      labelClassName="font-bold"
      title={{ tx: "auth.signUp.inputs.telephone.title" }}
      name="telephone"
      control={control}
      error={{ tx: errors.telephone?.message as TranslationFeKey }}
    />
  ),
};
