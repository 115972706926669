import * as Sentry from "@sentry/react";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CognitoClientMetadata } from "shared/types/cognito-client-metadata";
import { tx } from "shared/types/i18n";

import { SignInStatus } from "./cognito-magic-link-sign-up";
import { cognitoPaths } from "./paths";
import { SecondaryButton } from "../../../../components/form/button";
import { Form } from "../../../../components/form/form";
import { FormTextInput } from "../../../../components/form/form-text-input";
import { LoadingButton } from "../../../../components/form/loading-button";
import { Link } from "../../../../components/link";
import { Text } from "../../../../components/text";
import { useUserHasInteractedWithSignupForm } from "../../../../util/data-layer-actions";
import { isEmailRegex } from "../../../../util/regex";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { useAuthNavigateState } from "../../generic/use-auth-navigate-state";
import {
  CognitoError,
  handleCognitoError,
  MamaCognitoError,
} from "../misc/cognito-errors";
import { signUserIn } from "../misc/cognito-magic-link-methods";

export const CognitoMagicLinkSignIn: React.FC = () => {
  const { prefillValues } = useAuthNavigateState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<{
    name: string;
    email: string;
  }>({ defaultValues: prefillValues });

  useUserHasInteractedWithSignupForm(isDirty);

  const navigate = useAuthNavigate(
    () => ({
      email: getValues("email"),
      name: getValues("name"),
    }),
    [getValues],
  );

  const {
    i18n: { language },
  } = useTranslation();
  const { disease, organisation } = useTenantId();

  const clientMetadataMemo: CognitoClientMetadata & { [key: string]: string } =
    useMemo(
      () => ({
        disease,
        ...(organisation ? { organisation } : {}),
        isLocalhost: process.env.NODE_ENV === "development" ? "1" : "0",
        authFlowType: "CUSTOM_WITHOUT_SRP",
        signInMethod: "MAGIC_LINK",
        alreadyHaveMagicLink: "no",
        language,
      }),
      [disease, organisation, language],
    );

  const onSubmitTrySignUp = handleSubmit(({ email, name }) => {
    if (!clientMetadataMemo) {
      Sentry.captureException(
        new Error("Client metadata in sign in step is null"),
        {
          user: { email: email },
          level: "error",
        },
      );
      navigate({
        to: { type: "uri", uri: cognitoPaths.signIn },
        replace: true,
        state: {
          message: {
            level: AuthMessageLevel.ERROR,
            ...handleCognitoError(
              new MamaCognitoError({ name: "UnknownError" }),
            ),
          },
        },
      });
      return;
    }

    return signUserIn({ email, name, clientMetadata: clientMetadataMemo })
      .then((result) => {
        if (result.status === SignInStatus.MagicLinkSent) {
          navigate({
            to: { type: "uri", uri: cognitoPaths.confirmSignInInfo },
            replace: true,
          });
        } else {
          throw new MamaCognitoError({
            name: result.error?.name ?? "UnknownError",
            message:
              result.error?.message ??
              `Unexpected sign-in state ${result.status}: ${result.error}`,
          });
        }
      })
      .catch((err: CognitoError) => {
        Sentry.captureException(err, {
          user: { email: email },
          level: "error",
        });
        navigate({
          to: {
            type: "uri",
            uri:
              err.name === "UserNotFoundException"
                ? cognitoPaths.onboarding
                : cognitoPaths.magicSignIn,
          },
          replace: true,
          state: {
            message: {
              level: AuthMessageLevel.ERROR,
              ...handleCognitoError(err),
            },
          },
        });
      });
  });

  useEffect(() => {
    if (organisation) {
      navigate({ to: { type: "uri", uri: cognitoPaths.signIn } });
    }
  }, [navigate, organisation]);

  return (
    <Form onSubmit={onSubmitTrySignUp}>
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.email.title" }}
        placeholder={{ tx: "auth.signUp.inputs.email.placeholder" }}
        autoComplete="username"
        {...register("email", {
          required: tx("auth.signUp.inputs.email.fieldMissingError"),
          pattern: {
            value: isEmailRegex,
            message: tx("auth.signUp.inputs.email.notAnEmailError"),
          },
        })}
        error={{ txUnchecked: errors.email?.message }}
      />
      <LoadingButton
        type="submit"
        loading={isSubmitting}
        Button={SecondaryButton}
        tx="auth.signIn.signInButton"
      />
      <Text
        as="p"
        tx="auth.navigation.signUpInstead"
        txComponents={{
          SignUpLink: (
            <Link
              onClick={() => {
                navigate({ to: { type: "uri", uri: cognitoPaths.onboarding } });
              }}
            />
          ),
        }}
      />
    </Form>
  );
};
