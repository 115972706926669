"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ucbMgEu = void 0;
const analytics_app_pages_1 = require("../../../model/analytics-app-pages");
const customers_1 = require("../../../model/customers");
exports.ucbMgEu = {
    sidebarLogo: "/resources/mama-logo-wide.svg",
    customerLogo: "/resources/mama-logo-wide.svg",
    customer: customers_1.CustomerGroup.UCB_MG_EU,
    homePageRoute: analytics_app_pages_1.AnalyticsAppPagesEnum.OVERVIEW,
    pages: [
        {
            title: "Overview",
            route: analytics_app_pages_1.AnalyticsAppPagesEnum.OVERVIEW,
            iframeRoute: "/myasthenia-gravis/ucb/eu/overview",
        },
        {
            title: "Symptoms Explorer",
            route: analytics_app_pages_1.AnalyticsAppPagesEnum.SYMPTOMS_EXPLORER,
            iframeRoute: "/myasthenia-gravis/ucb/eu/symptoms-explorer",
        },
        {
            title: "Referral pathway",
            route: analytics_app_pages_1.AnalyticsAppPagesEnum.REFERRAL_PATHWAY,
            iframeRoute: "/myasthenia-gravis/ucb/eu/referral-pathway",
        },
        {
            title: "Treatment Pathway",
            route: analytics_app_pages_1.AnalyticsAppPagesEnum.TREATMENT_PATHWAY,
            iframeRoute: "/myasthenia-gravis/ucb/eu/treatment-pathway",
        },
        {
            title: "Mind and Behaviour",
            route: analytics_app_pages_1.AnalyticsAppPagesEnum.MIND_AND_BEHAVIOUR,
            iframeRoute: "/myasthenia-gravis/ucb/eu/mind-behaviour",
        },
    ],
    helpPage: {
        route: analytics_app_pages_1.AnalyticsAppPagesEnum.HELP,
        iframeRoute: "https://www.mamahealth.io/iframes/mama-answer-waitlist",
    },
};
