import { tx } from "shared/types/i18n";
import { TranslationFeKey } from "shared/types/translation-key";

import { UpdateUserAttributesDto } from "../../../../../api/generated/backend";
import { FormTextInput } from "../../../../../components/form/form-text-input";
import { FormDataCollectionInput } from "../types";

export const opadePatientIdInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.opadePatientId,
  Component: ({ errors, register, context }) => {
    return (
      <>
        <FormTextInput
          labelClassName="font-bold"
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          autoComplete="off"
          title={{ tx: "signUp.customFields.opadePatientId.title" }}
          placeholder={{ tx: "signUp.customFields.opadePatientId.placeholder" }}
          {...register("opadePatientId", {
            required: tx(
              "signUp.customFields.opadePatientId.errorUserDidNotEnterPatientId",
            ),
          })}
          error={{ txUnchecked: errors.opadePatientId?.message }}
        />
        {context !== "PROFILE" && (
          <FormTextInput
            labelClassName="font-bold"
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            autoComplete="off"
            title={{ tx: "signUp.customFields.opadePatientId.confirmTitle" }}
            placeholder={{
              tx: "signUp.customFields.opadePatientId.confirmPlaceholder",
            }}
            {...register(
              "opadePatientId_confirmation" as keyof UpdateUserAttributesDto,
              {
                required: tx(
                  "signUp.customFields.opadePatientId.errorUserDidNotConfirmPatientId",
                ),
                validate: (val, values): TranslationFeKey | undefined => {
                  if (values.opadePatientId !== val) {
                    return "signUp.customFields.opadePatientId.errorPatientIdsDidNotMatch";
                  }
                },
              },
            )}
            error={{
              txUnchecked:
                errors[
                  "opadePatientId_confirmation" as keyof UpdateUserAttributesDto
                ]?.message,
            }}
          />
        )}
      </>
    );
  },
};
