import { useMemo, useState } from "react";
import { organisationsPerDisease } from "shared/config";
import { Organisation } from "shared/model/organisations";
import { tx } from "shared/types/i18n";

import { useTenantId } from "../../../util/use-active-tenant-id";
import { DropdownProps } from "../../form/dropdown";
import { MultiSelectDropdown } from "../../form/multi-select-dropdown";
import { useFilterElementContext } from "../filter-element";
import {
  FilterComponent,
  FilterProperty,
  FilterType,
  PropertyFilterRule,
} from "../types";

export const OrgMultiSelectDropdownFilter: FilterComponent = () => {
  const tenantId = useTenantId();
  const { filterConfig, onFilterChange } = useFilterElementContext();
  const isOrgFilterSet = filterConfig?.some(
    (propFilter) => propFilter.property === FilterProperty.ORGANISATION,
  );

  const orgsOfDisease = useMemo(
    () => [...(organisationsPerDisease.get(tenantId.disease) ?? [])],
    [tenantId.disease],
  );

  const organisations = useMemo<DropdownProps<Organisation>["options"]>(
    () =>
      orgsOfDisease.map((org) => ({
        value: org,
        label: { tx: tx(`organisation.${org}.settings.name`) },
      })),
    [orgsOfDisease],
  );

  const [orgsDropdown, setOrgsDropdown] = useState<Array<Organisation>>([]);

  return isOrgFilterSet ? (
    <MultiSelectDropdown
      noValueSelected={{ tx: "filters.selectOrganisation" }}
      options={organisations}
      className="z-10"
      values={orgsDropdown}
      onChange={(orgs) => {
        setOrgsDropdown(orgs);
        onFilterChange({
          type: FilterType.FILTER,
          rule: PropertyFilterRule.ARE,
          value: orgs,
          property: FilterProperty.ORGANISATION,
        });
      }}
    />
  ) : null;
};
