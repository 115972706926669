import { updatePassword } from "aws-amplify/auth";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";
import { MIN_PASSWORD_LENGTH } from "shared/config/cognito";
import { tx } from "shared/types/i18n";
import { TranslationFeKey } from "shared/types/translation-key";
import { twMerge } from "tailwind-merge";

import { PlainError } from "~/components/events/plain-error";
import { SuccessEvent } from "~/components/events/success-event";
import { OutlineButton } from "~/components/form/button";
import { FormTextInput } from "~/components/form/form-text-input";
import { useStore } from "~/models/helpers";
import { useModal } from "~/models/modal-provider";

export const ChangePasswordButton: FC<JSX.IntrinsicElements["button"]> = ({
  className,
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<{
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  }>();
  const { showModal, hideModal } = useModal();
  const mainStore = useStore();

  const onSubmitTryChangePassword = handleSubmit(
    async ({ oldPassword, newPassword }) => {
      try {
        await updatePassword({ oldPassword, newPassword });
        hideModal();
        mainStore.addToastEvent(
          new SuccessEvent({ tx: "profile.password.passwordUpdated" }),
        );
      } catch (err) {
        console.log(err);
        mainStore.addToastEvent(
          new PlainError({ tx: "profile.password.errorUpdatingPassword" }),
        );
        hideModal();
      } finally {
        reset();
      }
    },
  );

  return (
    <OutlineButton
      className={twMerge("w-full cursor-pointer text-error", className)}
      tx="profile.password.changePassword"
      onClick={() => {
        showModal({
          title: { tx: "profile.password.changePassword" },
          onConfirm: () => {
            onSubmitTryChangePassword();
          },
          loading: { isLoading: isSubmitting },
          children: (
            <Form>
              <FormTextInput
                title={{ tx: "profile.password.currentPassword.title" }}
                placeholder={{
                  tx: "profile.password.currentPassword.placeholder",
                }}
                autoComplete="current-password"
                type="password"
                {...register("oldPassword", {
                  required: tx(
                    "profile.password.currentPassword.fieldMissingError",
                  ),
                })}
                error={{ txUnchecked: errors.oldPassword?.message }}
              />
              <FormTextInput
                title={{ tx: "profile.password.newPassword.title" }}
                placeholder={{
                  tx: "profile.password.newPassword.placeholder",
                }}
                autoComplete="new-password"
                type="password"
                {...register("newPassword", {
                  required: tx(
                    "profile.password.newPassword.fieldMissingError",
                  ),
                  minLength: {
                    value: MIN_PASSWORD_LENGTH,
                    message: tx(
                      "profile.password.newPassword.passwordTooShortError",
                    ),
                  },
                })}
                error={{
                  txUnchecked: errors.newPassword?.message,
                  txData: { min_password_length: MIN_PASSWORD_LENGTH },
                }}
              />
              <FormTextInput
                title={{ tx: "profile.password.confirmPassword.title" }}
                placeholder={{
                  tx: "profile.password.confirmPassword.placeholder",
                }}
                autoComplete="new-password"
                type="password"
                {...register("confirmNewPassword", {
                  required: tx(
                    "profile.password.confirmPassword.fieldMissingError",
                  ),
                  validate: (val: string): TranslationFeKey | undefined => {
                    if (getValues("newPassword") !== val) {
                      return "profile.password.confirmPassword.errorPasswordMismatch";
                    }
                  },
                })}
                error={{ txUnchecked: errors.confirmNewPassword?.message }}
              />
            </Form>
          ),
        });
      }}
    />
  );
};
