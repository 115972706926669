import { AcquisitionSources } from "shared/model/data-collection/guard/form-data-guard/default-form-data-guards/constants";
import { tx } from "shared/types/i18n";

import { FormDropdownInput } from "../../../../../components/form/form-dropdown-input";
import { FormDataCollectionInput } from "../types";

export const acquisitionSourceInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.name,
  Component: ({ errors, control, user }) => {
    const savedValue = user.userAttributes?.acquisitionSource;

    return !savedValue ? (
      <FormDropdownInput
        labelClassName="font-bold"
        className="input-bordered rounded-lg border-[1.5px]"
        title={{
          tx: "userData.WhereDidYouHearAboutMama.title",
        }}
        error={{ txUnchecked: errors.acquisitionSource?.message }}
        options={[
          {
            label: { tx: "userData.WhereDidYouHearAboutMama.socialMedia" },
            value: AcquisitionSources.SOCIAL_MEDIA,
          },
          {
            label: {
              tx: "userData.WhereDidYouHearAboutMama.patientOrganisation",
            },
            value: AcquisitionSources.PATIENT_ORGANISATION,
          },
          {
            label: { tx: "userData.WhereDidYouHearAboutMama.onTheNews" },
            value: AcquisitionSources.ON_THE_NEWS,
          },
          {
            label: { tx: "userData.WhereDidYouHearAboutMama.google" },
            value: AcquisitionSources.GOOGLE,
          },
          {
            label: { tx: "userData.WhereDidYouHearAboutMama.friend" },
            value: AcquisitionSources.FRIEND,
          },
          {
            label: { tx: "userData.WhereDidYouHearAboutMama.doctor" },
            value: AcquisitionSources.DOCTOR,
          },
          {
            label: { tx: "userData.WhereDidYouHearAboutMama.other" },
            value: AcquisitionSources.OTHER,
          },
        ]}
        noValueSelected={{
          tx: "userData.WhereDidYouHearAboutMama.placeholder",
        }}
        name="acquisitionSource"
        control={{
          ...control,
          ...control.register("acquisitionSource"),
        }}
        validate={(value) => {
          if (!value) {
            return tx("userData.WhereDidYouHearAboutMama.fieldMissingError");
          }
        }}
      />
    ) : null;
  },
};
