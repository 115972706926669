import MDEditor, { MDEditorProps } from "@uiw/react-md-editor";
import React from "react";
import { UseFormGetValues, UseFormRegisterReturn } from "react-hook-form";
import { PlainI18nProps } from "shared/types/i18n";

import { FormInput } from "./form-input";
import { CreateNewsStoryDto } from "../../api/generated/backend";

type FormMarkdownInputProps = Omit<UseFormRegisterReturn, "ref"> & {
  title: PlainI18nProps;
  error: PlainI18nProps;
  name: keyof CreateNewsStoryDto;
  getValues?: UseFormGetValues<CreateNewsStoryDto>;
} & Omit<MDEditorProps, "onChange" | "title">;

export const FormMarkdownInput = React.forwardRef<
  HTMLDivElement,
  FormMarkdownInputProps
>(({ error, onChange, getValues, name, title, ...rest }, ref) => {
  return (
    <FormInput title={title} error={error}>
      <MDEditor
        ref={ref}
        value={getValues?.(name)}
        onChange={(value?: string) => {
          onChange({ target: { name, value } });
        }}
        {...rest}
      />
    </FormInput>
  );
});

FormMarkdownInput.displayName = "FormMarkdownInput";
