import { Form } from "react-router-dom";
import { tx } from "shared/types/i18n";

import { EditNewsStoryProps } from "./news-story.props";
import { isUrlRegex } from "../../util/regex";
import { FormTextInput } from "../form/form-text-input";
import { FormLanguageDropDown } from "../form/language/form-language-dropdown-input";
import { LoadingButton } from "../form/loading-button";

export const EditExternalNewsStory: React.FC<EditNewsStoryProps> = ({
  register,
  formState,
  onSave,
  isLoading,
  isError,
  control,
}) => {
  return !isError ? (
    <Form
      onSubmit={onSave}
      className="flex min-w-[20rem] max-w-full flex-grow flex-col gap-1"
    >
      <FormTextInput
        title={{ tx: "editNews.link.label" }}
        placeholder={{ tx: "editNews.link.placeholder" }}
        {...register("link", {
          required: tx("editNews.link.error"),
          pattern: {
            value: isUrlRegex,
            message: tx("editNews.link.validation"),
          },
        })}
        error={{ txUnchecked: formState.errors.link?.message }}
      />
      <FormTextInput
        title={{ tx: "editNews.headline.label" }}
        placeholder={{ tx: "editNews.headline.placeholder" }}
        {...register("headline", {
          required: tx("editNews.headline.error"),
        })}
        error={{ txUnchecked: formState.errors.headline?.message }}
      />
      <FormTextInput
        title={{ tx: "editNews.description.label" }}
        placeholder={{ tx: "editNews.description.placeholder" }}
        as="textarea"
        {...register("description", {
          required: tx("editNews.description.error"),
        })}
        error={{ txUnchecked: formState.errors.description?.message }}
      />
      <FormTextInput
        title={{ tx: "editNews.imageLink.label" }}
        placeholder={{ tx: "editNews.imageLink.placeholder" }}
        {...register("imageLink", {
          required: tx("editNews.imageLink.error"),
          pattern: {
            value: isUrlRegex,
            message: tx("editNews.imageLink.validation"),
          },
        })}
        error={{ txUnchecked: formState.errors.imageLink?.message }}
      />
      <FormLanguageDropDown
        name="language"
        control={control}
        title={{ tx: "editNews.language.label" }}
        error={{ txUnchecked: formState.errors.language?.message }}
      />
      <LoadingButton
        type="submit"
        tx="editNews.save"
        className="ml-2 self-start"
        loading={formState.isSubmitting || isLoading}
      />
    </Form>
  ) : null;
};
