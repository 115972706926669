import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { PlainI18nProps } from "shared/types/i18n";

import { Checkbox, CheckboxProps } from "./checkbox";
import { FormInput } from "./form-input";

export type FormCheckboxInputProps = CheckboxProps &
  Omit<UseFormRegisterReturn, "ref"> & {
    title?: PlainI18nProps;
    error: PlainI18nProps;
    labelClassName?: string;
  };

export const FormCheckboxInput = React.forwardRef<
  HTMLInputElement,
  FormCheckboxInputProps
>(({ title, error, disabled, labelClassName, ...rest }, ref) => {
  return (
    <FormInput title={title} error={error} labelClassName={labelClassName}>
      <Checkbox isDisabled={disabled} ref={ref} {...rest} />
    </FormInput>
  );
});
FormCheckboxInput.displayName = "FormCheckboxInput";
