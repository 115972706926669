import { RouteObject } from "react-router-dom";
import { TenantIdentifier } from "shared/config";
import { PlainI18nProps } from "shared/types/i18n";

import { CognitoConfigurationState } from "../cognito/misc/types";

export enum AuthMessageLevel {
  INFO = "INFO",
  ERROR = "ERROR",
}

export interface AuthNavigateState {
  redirectUrl?: string;
  message?: {
    level: AuthMessageLevel;
  } & PlainI18nProps;
  prefillValues?: Record<string, string>;
}

export interface UpdateableProfileInformation {
  telephone?: string;
  nickname: string;
  email?: string;
}

export enum AuthStateEnum {
  SIGNED_IN = "SIGNED_IN",
  SIGNED_OUT = "SIGNED_OUT",
  LOADING = "LOADING",
}

export interface IAuthStore {
  readonly jwtToken?: string;
  readonly userData?: UserData;
  setup: () => Promise<void>;
  signOut: () => Promise<void>;
  getValidJwtToken: () => Promise<string | undefined>;
}

export interface IStaticAuthStore {
  probeIfUserIsSignedIn: () => Promise<void>;
}

export type AuthRouteGetter = (activeTenant: TenantIdentifier) => RouteObject[];

export interface IAuthStoreInitializer {
  init: (
    cognitoConfigState: CognitoConfigurationState,
    authStateDelegate: IAuthStateDelegate,
  ) => IAuthStore;
  getAuthRoutes: AuthRouteGetter;
}

export interface IAuthStateDelegate {
  readonly state: AuthStateEnum;
  setState: (newState: AuthStateEnum) => void;
  setAuthStoreDelegate: (authStoreDelegate: IAuthStore) => void;
}

export interface UserData {
  sub: string;
  email?: string;
}
