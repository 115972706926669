import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { tx } from "shared/types/i18n";

import { FormAddressInput } from "../../../../../components/form/form-address-input";
import { AddressComponentEnum } from "../../../../../components/map/constants";
import { FormDataCollectionInput } from "../types";

export const cityInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.city,
  Component: ({ errors, control }) => {
    const formContext = useFormContext();

    const updateFields = useCallback(
      (country?: string) => {
        formContext?.resetField("address");
        formContext?.resetField("postalCode");
        formContext?.setValue(
          "country",
          country ? JSON.parse(country).COUNTRY : "",
        );
      },
      [formContext],
    );

    return (
      <FormAddressInput
        name="city"
        labelClassName="font-bold"
        title={{ tx: "userData.city" }}
        control={control}
        error={{ txUnchecked: errors.city?.message }}
        addressComponents={[
          AddressComponentEnum.CITY,
          AddressComponentEnum.COUNTRY,
        ]}
        onSuggestionsClick={updateFields}
        validate={(value) => {
          if (!value || value === "") {
            return tx("userData.cityIsRequiredError");
          }
        }}
      />
    );
  },
};
