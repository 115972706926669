import React, { FocusEvent, useCallback, useRef, useState } from "react";
import { PlainI18nProps } from "shared/types/i18n";

import { AddressComponent } from "./constants";
import { useAddressSearch } from "./use-address-search";
import { PrimaryButton } from "../form/button";
import { TextInput } from "../form/text-input";
import { Text } from "../text";

export type AddressInputFieldProps = {
  value: string;
  onChange: (value: string) => void;
  label?: PlainI18nProps;
  onConfirm?: () => void;
  onSuggestionsClick?: (value: string) => void;
  disableUseAddressSearch?: boolean;
  addressComponents?: AddressComponent[];
  inputType?: "number" | "text";
};

export const AddressInputField: React.FC<AddressInputFieldProps> = ({
  value,
  onChange,
  onConfirm,
  onSuggestionsClick,
  label,
  disableUseAddressSearch,
  addressComponents,
  inputType = "text",
}) => {
  const { searchResult, setQuery } = useAddressSearch(addressComponents);
  const [showDropdown, setShowDropdown] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleBlur = useCallback((event: FocusEvent<HTMLDivElement>) => {
    if (ref.current && !ref.current.contains(event.relatedTarget)) {
      setShowDropdown(false);
    }
  }, []);

  return (
    <div
      ref={ref}
      tabIndex={-1}
      onBlur={handleBlur}
      className="relative flex w-full flex-1 gap-2"
    >
      <div className="flex w-full flex-col">
        {label && <Text {...label} />}
        <TextInput
          value={value}
          type={inputType}
          onFocus={() => setShowDropdown(true)}
          onChange={(e) => {
            const val = e.target.value;
            onChange(val);
            setQuery(val);
          }}
        />
      </div>
      {onConfirm && (
        <PrimaryButton
          className="mt-6"
          tx="general.confirm"
          onClick={onConfirm}
        />
      )}
      {!disableUseAddressSearch && showDropdown && searchResult.length > 0 && (
        <div className="absolute top-20 z-[10009] flex max-h-96 w-full flex-col gap-2 overflow-scroll rounded-md bg-white p-5 shadow-xl">
          {searchResult.map((result, idx) => (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onChange(result.address);
                setShowDropdown(false);
                onSuggestionsClick?.(JSON.stringify(result.detailedAddress));
              }}
              key={`${idx}-${result}`}
              className="cursor-pointer rounded-md p-2 hover:bg-slate-200"
            >
              <p>{result.address}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
