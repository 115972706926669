import React from "react";
import {
  Control,
  Controller,
  FieldPathByValue,
  PathValue,
  Validate,
} from "react-hook-form";
import { PlainI18nProps } from "shared/types/i18n";

import { FormInput } from "./form-input";
import {
  AddressInputField,
  AddressInputFieldProps,
} from "../map/address-input-field";

export type FormAddressInputProps<
  Values extends object,
  Name extends FieldPathByValue<Values, string | undefined>,
> = {
  title: PlainI18nProps;
  error: PlainI18nProps;
  disabled?: boolean;
  name: Name;
  control: Control<Values>;
  validate?: Validate<PathValue<Values, Name>, Values>;
  labelClassName?: string;
  isOptional?: boolean;
} & Omit<AddressInputFieldProps, "value" | "onChange">;

export const FormAddressInput = <
  Values extends object,
  Name extends FieldPathByValue<Values, string | undefined>,
>({
  title,
  error,
  control,
  name,
  validate,
  labelClassName,
  isOptional,
  ...rest
}: FormAddressInputProps<Values, Name>): ReturnType<React.FC> => {
  return (
    <FormInput
      title={title}
      error={error}
      labelClassName={labelClassName}
      isOptional={isOptional}
    >
      <Controller
        name={name}
        control={control}
        rules={{
          validate,
        }}
        render={({ field: { onChange, value } }) => (
          <AddressInputField
            value={value ?? ""}
            onChange={onChange}
            {...rest}
          />
        )}
      />
    </FormInput>
  );
};
