/* tslint:disable */
/* eslint-disable */
/**
 * Conversation Service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserMediaRecord } from './UserMediaRecord';
import {
    UserMediaRecordFromJSON,
    UserMediaRecordFromJSONTyped,
    UserMediaRecordToJSON,
} from './UserMediaRecord';

/**
 * 
 * @export
 * @interface MessageRecord
 */
export interface MessageRecord {
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    role: MessageRecordRoleEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    language: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    contentLocalized: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    dbId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRecord
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof MessageRecord
     */
    inputTime: Date;
    /**
     * 
     * @type {Array<UserMediaRecord>}
     * @memberof MessageRecord
     */
    media?: Array<UserMediaRecord>;
}


/**
 * @export
 */
export const MessageRecordRoleEnum = {
    Assistant: 'ASSISTANT',
    System: 'SYSTEM',
    User: 'USER'
} as const;
export type MessageRecordRoleEnum = typeof MessageRecordRoleEnum[keyof typeof MessageRecordRoleEnum];


/**
 * Check if a given object implements the MessageRecord interface.
 */
export function instanceOfMessageRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "contentLocalized" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "inputTime" in value;

    return isInstance;
}

export function MessageRecordFromJSON(json: any): MessageRecord {
    return MessageRecordFromJSONTyped(json, false);
}

export function MessageRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': json['role'],
        'content': json['content'],
        'language': json['language'],
        'contentLocalized': json['contentLocalized'],
        'dbId': !exists(json, 'dbId') ? undefined : json['dbId'],
        'id': json['id'],
        'inputTime': (new Date(json['inputTime'])),
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(UserMediaRecordFromJSON)),
    };
}

export function MessageRecordToJSON(value?: MessageRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': value.role,
        'content': value.content,
        'language': value.language,
        'contentLocalized': value.contentLocalized,
        'dbId': value.dbId,
        'id': value.id,
        'inputTime': (value.inputTime.toISOString()),
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(UserMediaRecordToJSON)),
    };
}

