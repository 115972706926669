"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeToOrganisationConfig = exports.allOrganisations = exports.Organisation = exports.NO_ORGANISATION_RESERVED_NAME = exports.OrganisationType = void 0;
const utility_1 = require("../utils/utility");
var OrganisationType;
(function (OrganisationType) {
    OrganisationType["PAG"] = "PAG";
})(OrganisationType || (exports.OrganisationType = OrganisationType = {}));
exports.NO_ORGANISATION_RESERVED_NAME = "_";
var Organisation;
(function (Organisation) {
    Organisation["AILC"] = "ailc";
    Organisation["DADI"] = "dadi";
    Organisation["TANALENTAMENTE"] = "tanalentamente";
    Organisation["DERMATITEATOPICADIARY"] = "dermatiteatopicadiary";
    Organisation["MG_ITALY"] = "miastenia-italia";
    Organisation["AENDO"] = "aendo";
    Organisation["NOI_CON_VOI"] = "noi-con-voi";
    Organisation["ENDO_CARE"] = "endo-care";
    Organisation["ENDOMETRIOSEVEREINIGUNG"] = "endometriosevereinigung";
})(Organisation || (exports.Organisation = Organisation = {}));
// note: the Organisation enum cannot contain an entry with the value of NO_ORGANISATION_RESERVED_NAME. The below test checks this at compile time.
(0, utility_1.assertType)()({});
exports.allOrganisations = (0, utility_1.allEnumValues)({
    [Organisation.AILC]: Organisation.AILC,
    [Organisation.DADI]: Organisation.DADI,
    [Organisation.TANALENTAMENTE]: Organisation.TANALENTAMENTE,
    [Organisation.DERMATITEATOPICADIARY]: Organisation.DERMATITEATOPICADIARY,
    [Organisation.MG_ITALY]: Organisation.MG_ITALY,
    [Organisation.AENDO]: Organisation.AENDO,
    [Organisation.NOI_CON_VOI]: Organisation.NOI_CON_VOI,
    [Organisation.ENDO_CARE]: Organisation.ENDO_CARE,
    [Organisation.ENDOMETRIOSEVEREINIGUNG]: Organisation.ENDOMETRIOSEVEREINIGUNG,
});
const mergeToOrganisationConfig = (disease, organisation) => {
    return {
        type: "organisation",
        homePage: organisation.homePage,
        organisationType: organisation.organisationType,
        identityProviders: disease.identityProviders,
        organisation: organisation.organisation,
        supportedLanguages: disease.supportedLanguages,
        theme: organisation.theme,
        enabledPages: organisation.enabledPages,
        publicPages: organisation.publicPages,
        authLogo: organisation.authLogo,
        headerLogo: disease.headerLogo,
        narrowHeaderLogo: disease.narrowHeaderLogo,
        favIcon: disease.favIcon,
        favIconHighres: disease.favIconHighres,
        tenantNameTx: organisation.tenantNameTx,
        autoConfirmUsers: organisation.autoConfirmUsers,
        showCallSupportButton: disease.showCallSupportButton,
        bookAppointmentToFixTimelineLink: disease.bookAppointmentToFixTimelineLink,
        groupSecretsArns: organisation.groupSecretsArns,
        metaDescriptionTx: organisation.metaDescriptionTx,
        disease: disease.disease,
        pagLanguages: organisation.pagLanguages,
        dataGuards: disease.dataGuards,
        userFeedback: disease.userFeedback,
        profileInputs: disease.profileInputs,
        hideTutorial: disease.hideTutorial,
    };
};
exports.mergeToOrganisationConfig = mergeToOrganisationConfig;
