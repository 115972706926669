"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerGroup = void 0;
var CustomerGroup;
(function (CustomerGroup) {
    /*
      Format: `${CompanyAbbreviation}_${DiseaseAbbreviation}_${Region}`
      Regex: /^[A-Z]*_[A-Z]*_[A-Z]*$/
      WARNING: Regex cannot be enforced by TypeScripts type system
    */
    CustomerGroup["LEO_CHE_CH"] = "LEO_CHE_CH";
    CustomerGroup["NOV_CU_IT"] = "NOV_CU_IT";
    CustomerGroup["UCB_MG_IT"] = "UCB_MG_IT";
    CustomerGroup["UCB_MG_DE"] = "UCB_MG_DE";
    CustomerGroup["UCB_MG_EU"] = "UCB_MG_EU";
    CustomerGroup["CHI_COPD_IT"] = "CHI_COPD_IT";
    CustomerGroup["ASZ_MG_AT"] = "ASZ_MG_AT";
    // Demo customer groups
    CustomerGroup["DEMO_CHE_CH"] = "DEMO_CHE_CH";
})(CustomerGroup || (exports.CustomerGroup = CustomerGroup = {}));
