import { Form } from "react-router-dom";
import { tx } from "shared/types/i18n";

import { EditNewsStoryProps } from "./news-story.props";
import { isUrlRegex } from "../../util/regex";
import { FormMarkdownInput } from "../form/form-markdown-input";
import { FormTextInput } from "../form/form-text-input";
import { FormLanguageDropDown } from "../form/language/form-language-dropdown-input";
import { LoadingButton } from "../form/loading-button";

export const EditInternalNewsStory: React.FC<EditNewsStoryProps> = ({
  register,
  formState,
  onSave,
  isError,
  isLoading,
  getValues,
  control,
}) => {
  return !isError ? (
    <Form
      onSubmit={onSave}
      className="flex min-w-[20rem] max-w-full flex-grow flex-col gap-1"
    >
      <FormTextInput
        title={{ tx: "editNews.headline.label" }}
        placeholder={{ tx: "editNews.headline.placeholder" }}
        {...register("headline", {
          required: tx("editNews.headline.error"),
        })}
        error={{ txUnchecked: formState.errors.headline?.message }}
      />
      <FormTextInput
        title={{ tx: "editNews.description.label" }}
        placeholder={{ tx: "editNews.description.placeholder" }}
        as="textarea"
        {...register("description", {
          required: tx("editNews.description.error"),
        })}
        error={{ txUnchecked: formState.errors.description?.message }}
      />
      <FormTextInput
        title={{ tx: "editNews.imageLink.label" }}
        placeholder={{ tx: "editNews.imageLink.placeholder" }}
        {...register("imageLink", {
          required: tx("editNews.imageLink.error"),
          pattern: {
            value: isUrlRegex,
            message: tx("editNews.imageLink.validation"),
          },
        })}
        error={{ txUnchecked: formState.errors.imageLink?.message }}
      />
      <FormMarkdownInput
        title={{ tx: "editNews.body.label" }}
        data-color-mode="light"
        error={{ txUnchecked: formState.errors.body?.message }}
        getValues={getValues}
        {...register("body", { required: tx("editNews.body.error") })}
      />
      <FormLanguageDropDown
        name="language"
        control={control}
        title={{ tx: "editNews.language.label" }}
        error={{ txUnchecked: formState.errors.language?.message }}
      />
      <div className="flex gap-2">
        <LoadingButton
          type="submit"
          loading={formState.isSubmitting || isLoading}
          tx="editNews.save"
          className="ml-2 self-start"
        />
      </div>
    </Form>
  ) : null;
};
