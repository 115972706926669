import { tx } from "shared/types/i18n";
import { TranslationFeKey } from "shared/types/translation-key";

import { FormTextInput } from "../../../../../components/form/form-text-input";
import { FormDataCollectionInput } from "../types";

export const nameInput: FormDataCollectionInput = {
  wasCompleted: (user) => !!user.userAttributes?.name,
  Component: ({ errors, register, user }) => {
    const showNameInput =
      (!user.userAttributes?.name && !user.userAttributes?.finishedSignUp) ||
      user.userAttributes.finishedSignUp;

    return showNameInput ? (
      <FormTextInput
        labelClassName="font-bold"
        title={{ tx: "auth.signUp.inputs.name.title" }}
        placeholder={{ tx: "auth.signUp.inputs.name.placeholder" }}
        {...register("name", {
          validate: (value) => {
            if (!value?.trim()) {
              return tx("auth.signUp.inputs.name.fieldMissingError");
            }
          },
        })}
        error={{ tx: errors.name?.message as TranslationFeKey }}
      />
    ) : null;
  },
};
