import { tx } from "shared/types/i18n";

import { FormCheckboxInput } from "../../../../../components/form/form-checkbox-input";
import { Link } from "../../../../../components/link";
import { FormDataCollectionInput } from "../types";

export const termsAndConditions20230830Input: FormDataCollectionInput = {
  wasCompleted: (user) =>
    !!user.userAttributes?.agreedToTermsAndConditions20230830,
  Component: ({ errors, register }) => {
    return (
      <div>
        <FormCheckboxInput
          labelClassName="font-bold"
          title={{ tx: "signUp.customFields.mamaTermsAndConditions.title" }}
          label={{
            tx: "signUp.customFields.mamaTermsAndConditions.agreeToTermsAndConditions",
            txComponents: {
              href: <Link target="_blank" className="underline" />,
            },
          }}
          {...register("agreedToTermsAndConditions20230830", {
            required: tx(
              "signUp.customFields.mamaTermsAndConditions.errorUserDidNotAgreeToTermsAndConditions",
            ),
          })}
          error={{
            txUnchecked: errors.agreedToTermsAndConditions20230830?.message,
          }}
        />
      </div>
    );
  },
};
