import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "shared/model/languages";

import { ManageCategoriesModal } from "./manage-categories-modal";
import {
  CreateSocialMediaPostCategoryRequest,
  DeleteSocialMediaPostCategoryRequest,
  UpdateSocialMediaPostCategoryRequest,
  UpsertSocialMediaPostCategoryDto,
} from "../../../api/generated/backend";
import { toTenantIdHeader, useApiMutation } from "../../../api/use-api";
import { useModal } from "../../../models/modal-provider";
import {
  SOCIAL_MEDIA_POST_CATEGORIES,
  SOCIAL_MEDIA_POST_CATEGORIES_GLOBAL,
  SOCIAL_MEDIA_POSTS,
} from "../../../types/query-keys";
import { useDetailsBlur } from "../../../util/hooks";
import { useTenantId } from "../../../util/use-active-tenant-id";
import { Button } from "../../form/button";
import { SvgIcon } from "../../icons/svg-icon";
import { Text } from "../../text";

export const ManageSocialMediaPostCategoriesList: React.FC = () => {
  const queryClient = useQueryClient();
  const tenantId = useTenantId();
  const dropDownRef = useRef<HTMLDetailsElement>(null);
  const {
    i18n: { language: activeLanguageString },
  } = useTranslation();
  const activeLanguage = activeLanguageString as Language;

  const {
    showModal: showManageCategoriesModal,
    hideModal: hideManageCategoriesModal,
  } = useModal();

  useDetailsBlur(dropDownRef);

  const { mutate: createSocialMediaPostCategory } = useApiMutation(
    "backend",
    (api) => (request: CreateSocialMediaPostCategoryRequest) =>
      api.createSocialMediaPostCategory(request),
    undefined,
    {
      successMessage: {
        tx: "socialMedia.categories.categorySuccesfullyCreated",
      },
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          SOCIAL_MEDIA_POST_CATEGORIES(activeLanguage),
        );
        queryClient.invalidateQueries(SOCIAL_MEDIA_POST_CATEGORIES_GLOBAL);
        queryClient.invalidateQueries(SOCIAL_MEDIA_POSTS(tenantId));
      },
    },
  );

  const { mutate: updateSocialMediaPostCategory } = useApiMutation(
    "backend",
    (api) => (request: UpdateSocialMediaPostCategoryRequest) =>
      api.updateSocialMediaPostCategory(request),
    undefined,
    {
      successMessage: {
        tx: "socialMedia.categories.categorySuccesfullyUpdated",
      },
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          SOCIAL_MEDIA_POST_CATEGORIES(activeLanguage),
        );
        queryClient.invalidateQueries();
        queryClient.invalidateQueries(SOCIAL_MEDIA_POSTS(tenantId));
      },
    },
  );

  const { mutate: deleteSocialMediaPostCategory } = useApiMutation(
    "backend",
    (api) => (request: DeleteSocialMediaPostCategoryRequest) =>
      api.deleteSocialMediaPostCategory(request),
    undefined,
    {
      successMessage: {
        tx: "socialMedia.categories.categorySuccesfullyDeleted",
      },
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          SOCIAL_MEDIA_POST_CATEGORIES(activeLanguage),
        );
        queryClient.invalidateQueries(SOCIAL_MEDIA_POST_CATEGORIES_GLOBAL);
        queryClient.invalidateQueries(SOCIAL_MEDIA_POSTS(tenantId));
      },
    },
  );

  const createCategory = useCallback(
    (newCategory: UpsertSocialMediaPostCategoryDto) => {
      const payload = {
        ...toTenantIdHeader(tenantId),
        upsertSocialMediaPostCategoryDto: {
          ...newCategory,
        },
      };
      createSocialMediaPostCategory(payload);
    },
    [createSocialMediaPostCategory, tenantId],
  );

  const updateCategory = useCallback(
    (
      id: string,
      upsertSocialMediaPostCategoryDto: UpsertSocialMediaPostCategoryDto,
    ) => {
      updateSocialMediaPostCategory({
        ...toTenantIdHeader(tenantId),
        id,
        upsertSocialMediaPostCategoryDto,
      });
    },
    [tenantId, updateSocialMediaPostCategory],
  );

  const deleteCategory = useCallback(
    (id: string) => {
      const category = {
        ...toTenantIdHeader(tenantId),
        id: id,
      };
      deleteSocialMediaPostCategory(category);
    },
    [deleteSocialMediaPostCategory, tenantId],
  );

  return (
    <Button
      className=" btn-ghost btn m-1 flex h-full w-80 flex-nowrap items-center justify-start whitespace-nowrap "
      as="div"
      onClick={(e) => {
        e.preventDefault();
        showManageCategoriesModal({
          children: (
            <ManageCategoriesModal
              createCategory={createCategory}
              updateCategory={updateCategory}
              deleteCategory={deleteCategory}
            />
          ),
          className: "max-w-[80%] lg:max-w-[60%] py-4 px-4 md:overflow-visible",
          showsWindowCloseButton: true,
          showCloseButton: false,
          closeOnClickOutside: true,
          onClose: () => {
            hideManageCategoriesModal();
          },
        });
      }}
    >
      <SvgIcon icon="gear" className="h-6 w-6" />
      <Text
        className="label-text ml-2 whitespace-nowrap text-base font-bold"
        as="span"
        tx="socialMedia.categories.manageCategories"
      />
    </Button>
  );
};
