import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { PlainI18nProps } from "shared/types/i18n";

import { FormInput } from "./form-input";
import { TextInput, TextInputProps } from "./text-input";

export type FormTextInputProps = TextInputProps &
  Omit<UseFormRegisterReturn, "ref"> & {
    title?: PlainI18nProps;
    error: PlainI18nProps;
    labelClassName?: string;
    isOptional?: boolean;
  };

export const FormTextInput = React.forwardRef<
  HTMLInputElement,
  FormTextInputProps
>(({ title, error, disabled, labelClassName, isOptional, ...rest }, ref) => {
  return (
    <FormInput
      title={title}
      error={error}
      isOptional={isOptional}
      labelClassName={labelClassName}
    >
      <TextInput ref={ref} isDisabled={disabled} {...rest} />
    </FormInput>
  );
});
FormTextInput.displayName = "FormTextInput";
