import {
  forwardRef,
  MutableRefObject,
  RefObject,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useForm } from "react-hook-form";
import { Form } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Language } from "shared/model/languages";
import { tx } from "shared/types/i18n";

import { PrimaryButton, SecondaryButton } from "./form/button";
import { FormTextInput } from "./form/form-text-input";
import { LoadingButton } from "./form/loading-button";
import { LoadingIndicator } from "./loading-spinner";
import { Timeline } from "./timeline";
import { UserInfo } from "./user-info";
import { LogUserEventRequest, TimelineDto } from "../api/generated/backend";
import { EventDetailRecord, EventRecord } from "../api/generated/landbot";
import { UserActivityLogType } from "../api/generated/multiagent";
import { toTenantIdHeader, useApiMutation } from "../api/use-api";
import { DefaultContentContainer } from "../layouts/default-content-container";
import { useTenantConfig } from "../tenant-settings";
import { isEmailRegex } from "../util/regex";
import { useTenantId } from "../util/use-active-tenant-id";
import { useUser } from "../util/use-user";

export const PrintableJourney: React.FC<{
  timeline: EventRecord[];
  userDetails: EventDetailRecord[];
  colors: string[];
}> = ({ timeline, userDetails, colors }) => {
  const timelineRef = useRef<HTMLDivElement | null>(null);
  useCtrlPToPrint(timelineRef);

  return (
    <DefaultContentContainer>
      <div ref={timelineRef}>
        <TimelineActionRow userDetails={userDetails} ref={timelineRef} />
        <Timeline colors={colors} timeline={timeline} />
      </div>
    </DefaultContentContainer>
  );
};

// eslint-disable-next-line react/display-name
const TimelineActionRow = forwardRef<
  HTMLDivElement,
  { userDetails: EventDetailRecord[] }
>(({ userDetails }, ref) => {
  const config = useTenantConfig();

  const user = useUser();
  const tenantId = useTenantId();

  const language = useMemo(
    () => user?.userAttributes?.language ?? "en-US",
    [user?.userAttributes?.language],
  );

  const print = useReactToPrint({
    content: () => (ref as MutableRefObject<HTMLDivElement | null>).current,
  });

  const {
    register,
    reset,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm<TimelineDto>({
    defaultValues: {
      to: user?.userAttributes?.email,
      language: language as Language,
    },
  });

  const { mutate: logUserEvent } = useApiMutation(
    "backend",
    (api) => (logUserActivityRequest: LogUserEventRequest) =>
      api.logUserEvent(logUserActivityRequest),
  );

  const { mutate: sendTimelineViaEmail } = useApiMutation(
    "backend",
    (api) => (timelineDto: TimelineDto) =>
      api.sendTimeline({
        timelineDto,
        ...toTenantIdHeader(tenantId),
      }),
    undefined,
    {
      successMessage: {
        tx: "journey.timelineSent",
      },
    },
    {
      onSuccess() {
        logUserEvent({
          userEventDto: {
            type: UserActivityLogType.Event,
            info: { message: "User sent timeline via email" },
          },
        });
        reset();
      },
    },
  );

  const sendTimelineViaEmailHandler = handleSubmit(
    async (data: TimelineDto) => {
      sendTimelineViaEmail({
        ...data,
        language,
      });
    },
  );

  return (
    <div className="w-90 mx-auto mb-12 flex max-w-full flex-col items-center justify-center">
      <UserInfo userDetails={userDetails} />
      <div className="mt-3 flex w-full flex-row justify-center gap-3 print:hidden">
        {user ? (
          <Form
            className="-mx-2 flex w-[70%] flex-row justify-center"
            onSubmit={sendTimelineViaEmailHandler}
          >
            <div className="flex w-full flex-col xl:max-w-[600px] 2xl:max-w-[700px]">
              <FormTextInput
                placeholder={{ tx: "journey.email.placeholder" }}
                autoComplete="username"
                {...register("to", {
                  required: tx("journey.email.fieldMissingError"),
                  pattern: {
                    value: isEmailRegex,
                    message: tx("journey.email.notAnEmailError"),
                  },
                })}
                error={{ txUnchecked: errors.to?.message }}
              />
              <div className="flex flex-col justify-center md:flex-row md:justify-around">
                <LoadingButton
                  className="w-[90%] self-center md:w-[47%]"
                  loading={isSubmitting}
                  tx="general.send"
                />
                <PrimaryButton
                  className="mt-2  w-[90%] self-center md:mt-0  md:w-[47%]"
                  onClick={(event) => {
                    event.preventDefault();
                    print();
                  }}
                  tx="general.print"
                />
              </div>
            </div>
          </Form>
        ) : (
          <LoadingIndicator as="spinner" />
        )}
        {config?.bookAppointmentToFixTimelineLink && (
          <SecondaryButton
            className="max-w-full"
            onClick={() => {
              window.open(config.bookAppointmentToFixTimelineLink, "_blank");
            }}
            tx="journey.bookAppointmentButton"
          />
        )}
      </div>
    </div>
  );
});

const useCtrlPToPrint = (printRef: RefObject<HTMLDivElement | null>): void => {
  const print = useReactToPrint({ content: () => printRef.current });
  useEffect(() => {
    const listener = (event: DocumentEventMap["keydown"]): void => {
      if ((event.ctrlKey || event.metaKey) && event.keyCode === 80) {
        event.preventDefault();
        event.stopImmediatePropagation();
        print();
      }
    };
    document.addEventListener("keydown", listener, true);
    return () => document.removeEventListener("keydown", listener, true);
  }, [print]);
};
