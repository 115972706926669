import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { SocialMediaMedia } from "shared/types/social-media-media";
import { TranslationFeKey } from "shared/types/translation-key";

import { SocialMediaPostComment } from "./social-media-post-comment";
import {
  CommentSocialMediaPostRequest,
  DeleteSocialMediaPostCommentRequest,
  LikeSocialMediaPostRequest,
  SocialMediaPostMedia,
} from "../../api/generated/backend";
import {
  toTenantIdHeader,
  useApiMutation,
  useApiQuery,
} from "../../api/use-api";
import { useProfile } from "../../data-collection/profile/profile";
import { useStore } from "../../models/helpers";
import {
  ME_KEY,
  SOCIAL_MEDIA_COMMENTS,
  SOCIAL_MEDIA_MEDIA,
  SOCIAL_MEDIA_POST,
  SOCIAL_MEDIA_POSTS,
} from "../../types/query-keys";
import { useTenantId } from "../../util/use-active-tenant-id";
import { Carousel } from "../carousel";
import { PlainError } from "../events/plain-error";
import { GhostButton, PrimaryButton } from "../form/button";
import { TextInput } from "../form/text-input";
import { LoadingScreen } from "../loading-screen";
import { SocialShareButtons } from "../social-share-buttons";

interface SocialMediaDetailProps {
  id: string;
}
interface SocialMediaContentProps {
  id: string;
  media: SocialMediaPostMedia;
}

export const SocialMediaPostDetail: React.FC<SocialMediaDetailProps> = ({
  id,
}) => {
  const { data: media } = useApiQuery(
    "backend",
    (api) =>
      api.getSocialMediaPostMedia({
        socialMediaPostIdDto: { postId: id },
      }),
    SOCIAL_MEDIA_MEDIA(id),
  );

  return media ? (
    <div className="flex h-full flex-col overflow-y-scroll pt-5 md:flex-row md:gap-5  md:overflow-y-auto">
      <SocialMediaContentSection id={id} media={media} />
      <SocialMediaCommentSection id={id} />
    </div>
  ) : null;
};

const SocialMediaContentSection: React.FC<SocialMediaContentProps> = ({
  id,
  media,
}) => {
  const tenantId = useTenantId();
  const queryClient = useQueryClient();

  const { data: post, isFetching: isFetchingPost } = useApiQuery(
    "backend",
    (api) => api.getSocialMediaPost({ id: id ?? "" }),
    SOCIAL_MEDIA_POST,
    undefined,
  );

  const { mutate: likePost } = useApiMutation(
    "backend",
    (api) => (request: LikeSocialMediaPostRequest) =>
      api.likeSocialMediaPost(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SOCIAL_MEDIA_POST);
        queryClient.invalidateQueries(SOCIAL_MEDIA_POSTS(tenantId));
      },
    },
  );

  return (
    <div className="flex flex-1 flex-col md:overflow-y-auto">
      <div className="flex h-full  flex-col gap-5 ">
        {media?.mediaType === SocialMediaMedia.CAROUSEL ? (
          <Carousel images={media.mediaUrls} />
        ) : media?.mediaType === SocialMediaMedia.IMAGE ? (
          <SocialMediaImage src={media.mediaUrls[0]} />
        ) : null}
      </div>
      <div className=" text-xl md:h-full">
        {isFetchingPost ? (
          <LoadingScreen />
        ) : (
          post?.body
            .split("\n")
            .map((segment, index) => <p key={index}>{segment}</p>)
        )}
      </div>

      <div className="flex self-center">
        <GhostButton
          className="my-2"
          icon="heart"
          text={String(post?.likes)}
          iconColor={post?.liked ? "fill-error" : "fill-neutral"}
          onClick={() => {
            likePost({ socialMediaPostIdDto: { postId: id } });
          }}
        />
      </div>
      <SocialShareButtons className="my-2 self-center" />
    </div>
  );
};

const SocialMediaCommentSection: React.FC<
  Omit<SocialMediaDetailProps, "media">
> = ({ id }) => {
  const store = useStore();
  const tenantId = useTenantId();
  const queryClient = useQueryClient();
  const [commentText, setCommentText] = useState("");
  const { showProfile } = useProfile();

  const { data: user } = useApiQuery(
    "backend",
    (api) => api.getMe(toTenantIdHeader(tenantId)),
    ME_KEY(tenantId),
  );

  const { t } = useTranslation();

  const { mutate: commentPost } = useApiMutation(
    "backend",
    (api) => (request: CommentSocialMediaPostRequest) =>
      api.commentSocialMediaPost(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SOCIAL_MEDIA_COMMENTS(tenantId));
        queryClient.invalidateQueries(SOCIAL_MEDIA_POSTS(tenantId));
      },
    },
  );

  const { mutate: deleteComment } = useApiMutation(
    "backend",
    (api) => (request: DeleteSocialMediaPostCommentRequest) =>
      api.deleteSocialMediaPostComment(request),
    undefined,
    undefined,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(SOCIAL_MEDIA_COMMENTS(tenantId));
        queryClient.invalidateQueries(SOCIAL_MEDIA_POSTS(tenantId));
      },
    },
  );

  const { data: comments, isFetching: isFetchingComments } = useApiQuery(
    "backend",
    (api) =>
      api.listSocialMediaPostsComments({
        socialMediaPostIdDto: { postId: id },
      }),
    SOCIAL_MEDIA_COMMENTS(tenantId),
    undefined,
  );

  const submitComment = useCallback(() => {
    commentPost({
      socialMediaCommentDto: { commentBody: commentText, postId: id },
    });
    setCommentText("");
  }, [commentPost, commentText, id]);

  const handleCommentSubmit = useCallback(() => {
    if (!commentText) {
      store.addToastEvent(
        new PlainError({ tx: "socialMedia.commentCannotBeEmpty" }),
      );
      return;
    }

    if (!user?.userAttributes?.name) {
      showProfile();
      store.addToastEvent(
        new PlainError({ tx: "socialMedia.informationRequired" }),
      );
      return;
    }

    submitComment();
  }, [
    commentText,
    user?.userAttributes?.name,
    submitComment,
    store,
    showProfile,
  ]);

  return (
    <div className="relative flex-1 pb-16">
      <div className="max-h-full flex-1 overflow-y-auto">
        <div className="flex flex-col gap-3">
          {isFetchingComments ? (
            <LoadingScreen />
          ) : comments?.length ? (
            comments.map((comment) => (
              <SocialMediaPostComment
                key={comment.id}
                {...comment}
                onDeleteComment={deleteComment}
              />
            ))
          ) : (
            <SocialMediaPostComment
              id=""
              userId=""
              createdAt={new Date()}
              onDeleteComment={() => {}}
              isCurrentUserComment={false}
              body={t("socialMedia.beRespectfulInComments" as TranslationFeKey)}
            />
          )}
        </div>
        <div className="absolute bottom-0 mb-2 flex w-full gap-2 bg-white pt-2">
          <TextInput
            value={commentText}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                submitComment();
                event.preventDefault();
                event.stopPropagation();
              }
            }}
            onChange={(e) => {
              setCommentText(e.target.value);
            }}
          />
          <PrimaryButton onClick={handleCommentSubmit} icon="chat" />
        </div>
      </div>
    </div>
  );
};

const SocialMediaImage: React.FC<{ src: string }> = ({ src }) => {
  return <img className="h-full w-full rounded-md object-contain" src={src} />;
};
